import React, { useState, useGlobal } from 'reactn'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Password from '@kaizen-ui/password'
import Button from '@kaizen-ui/button'
//import Text from '@kaizen-ui/text'
import Modal from '@kaizen-ui/modal'
import Block from '@kaizen-ui/block'
import { useAuth } from '../../hooks'
import { ROUTE_DASHBOARD } from '../../Components'
import { FloatingSpinner, FormikError } from 'common'
import { ForgotPassword } from './ForgotPassword'
import { GLOBAL_IS_USER_LOCKED, GLOBAL_SERVER_DOWN } from '../../globalState'
import ReactTooltip from 'react-tooltip'
import { ServerErrorPage } from '../ErrorPages'

const LoginBox = styled.div`
  max-width: 500px;
  margin-top: 1rem;

  h1 {
    margin: 0;
    font-weight: 100;
    color: #76b900;
    margin-bottom: 2rem;
  }
`
const Buttons = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  > button.primary {
    padding-left: 0;
  }
`
const PullRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .kaizen-button.link {
    padding: 0 0.25rem;
  }
`
const Actions = styled.div`
  margin-top: 2rem;
`

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .max(250, 'Your username must be less than 250 characters')
    .required('You must enter a username'),
  password: Yup.string().required('You must enter a password')
})

const LOGIN_ERR = {
  USER_LOCKED: 'USER_LOCKED',
  LOGIN_FAILED: 'LOGIN_FAILED'
}
export const Login = () => {
  const history = useHistory()
  const location = useLocation()
  const [isUserLocked, setIsUserLocked] = useGlobal(GLOBAL_IS_USER_LOCKED)
  const [serverDown] = useGlobal(GLOBAL_SERVER_DOWN)
  const { user, signin, signout, meError } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { from } = location.state || { from: { pathname: '/' } }
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)

  React.useEffect(() => {
    document.title = 'DLS - Login'
  }, [])

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: values => {
      setLoading(true)
      ReactTooltip.hide()
      signin(values, result => {
        const { success, message } = result
        if (success) {
          history.replace(from)
        } else {
          setLoading(false)
          if (message && typeof message === 'string') {
            if (
              message
                .toLowerCase()
                .includes(LOGIN_ERR.USER_LOCKED.toLowerCase())
            ) {
              setIsUserLocked(true)
            } else if (
              message
                .toLowerCase()
                .includes(LOGIN_ERR.LOGIN_FAILED.toLowerCase())
            ) {
              const splitMsg = message.split(',')?.[1]?.trim()
              const attemptCount = splitMsg ? parseInt(splitMsg) : 0
              setError(
                attemptCount > 1
                  ? `Invalid username or password. Attempts left before the account is locked - ${4 -
                      attemptCount}`
                  : 'Invalid username or password'
              )
            } else {
              setError('Invalid username or password')
            }
          } else {
            setError('Invalid username or password')
          }

          formik.resetForm()
        }
      })
    },
    validationSchema: LoginSchema
  })

  if (serverDown) {
    return <ServerErrorPage error={meError} />
  }

  return (
    <>
      <LoginBox>
        <Block>
          <h1>Login</h1>
          <FloatingSpinner visible={loading} />
          {user && !isUserLocked ? (
            <>
              <div>You are already logged in as {user.email || user.name}</div>
              <div>
                <Flex>
                  If you would like to login as a different user, you must{' '}
                  <Button variant='link' size='large' onClick={signout}>
                    logout
                  </Button>
                  {'  '}
                  first
                </Flex>
                <Actions>
                  <Button
                    icon={{ name: 'ObjectsHome' }}
                    onClick={() => history.push(ROUTE_DASHBOARD)}
                  >
                    Go To Dashboard
                  </Button>
                </Actions>
              </div>
            </>
          ) : null}
          {isUserLocked && (
            <>
              <FormikError>
                Your account has been temporarily locked for 30 minutes due to
                four consecutive invalid login attempts.
              </FormikError>
              <div>
                <p>
                  You can still unlock your account by going through the forgot
                  password process.
                </p>
                <Actions>
                  <Button onClick={() => setForgotPasswordOpen(true)}>
                    Forgot password?
                  </Button>
                </Actions>
              </div>
            </>
          )}
          {!user && !isUserLocked ? (
            <form>
              <div>
                <div>
                  <Textbox
                    name='username'
                    label='Username'
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    required={formik.touched.username && formik.errors.username}
                    validationMessage={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </div>
              </div>
              <div>
                <div data-tip='Enter your password'>
                  <Password
                    name='password'
                    label='Password'
                    showVisibilityToggle={false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    required={formik.touched.password && formik.errors.password}
                    validationMessage={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
              </div>
              {error && (
                <div>
                  <FormikError>{error}</FormikError>
                  <br />
                </div>
              )}
              <Buttons>
                <Button
                  variant='link'
                  onClick={() => setForgotPasswordOpen(true)}
                >
                  Forgot password?
                </Button>
                <PullRight>
                  <Button
                    tag='submit'
                    onClick={formik.handleSubmit}
                    //disabled={!formik.isValid}
                  >
                    LOG IN
                  </Button>
                </PullRight>
              </Buttons>
            </form>
          ) : null}
        </Block>
      </LoginBox>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Reset Password'
          subtitle={`Reset the password with the local reset secret provided during setup, or downloaded from NVIDIA Licensing Portal`}
          open={forgotPasswordOpen}
          onClose={() => setForgotPasswordOpen(false)}
        >
          {forgotPasswordOpen && (
            <ForgotPassword
              onPasswordChange={() => {
                setIsUserLocked(false)
                setError('')
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
