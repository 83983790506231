import {
  Button,
  TagEditor,
  Text,
  Textbox,
  KaizenThemeContext,
  DatePicker,
  Icon,
  Block
} from '@kaizen-ui/complete'
import {
  //DurationSelector,
  FileUpload,
  formatDate,
  formatExpirationDate,
  InlineButton,
  ModalButtons,
  ModalSelect,
  ModalSpinner,
  Table,
  UsageIndicator,
  useAppNotifications,
  useFetch
} from 'common'
import React from 'react'
import {
  API_NLL,
  LS_FEATURE_STATUS_EXPIRED,
  LS_FEATURE_STATUS_EXPIRING
} from '../../api'
import { saveAs } from 'file-saver'
import styled from 'styled-components'
import moment from 'moment'
import { useEffect } from 'reactn'
import ReactTooltip from 'react-tooltip'
import { FormikError } from 'common'

const AddContent = styled.div`
  display: flex;
  align-items: center;

  .kaizen-button {
    padding-right: 0;
  }

  max-width: 600px;
  margin-bottom: -1rem;
`

const validMACaddress = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/

export const GenerateNll = ({ serviceInstanceId, server, onUpdate }) => {
  const theme = React.useContext(KaizenThemeContext)
  const { name, licensePools, scopeReference } = server
  const featureOptions = React.useMemo(() => {
    return [
      ...new Set(
        licensePools?.[0]?.licensePoolFeatures?.map(
          pf => pf?.feature?.displayLabel
        )
      )
    ]?.map(label => {
      return {
        label,
        value: licensePools[0]?.licensePoolFeatures?.find(
          pf => pf?.feature?.displayLabel === label
        )?.feature
      }
    })
  }, [licensePools])

  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_NLL,
    actionLabel: `Generating disconnected lease for server ${name}`,
    method: 'POST',
    returnBlob: true
  })
  const submit = async () => {
    const expire = expireDate ? { lease_expire: expireDate } : {}
    const body = {
      nll_lease_request: macs.map((mac, index) => {
        return {
          id: `VM${index}`,
          fulfillment_context: {
            fulfillment_class_ref_list: []
          },
          platform_evidence: [{ id: 'MAC', val: mac }],
          lease_proposal_list: features.map(option => {
            return {
              product: {
                name: option.value.featureName,
                version: option.value.featureVersion
              },
              ...expire,
              license_type_qualifiers: {
                count: 1
              }
            }
          }),
          scope_ref_list: [scopeReference]
        }
      })
    }

    const result = await sendRequest({
      body,
      headers: {
        'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
      }
    })
    if (result) {
      saveAs(result, `nll.zip`)
      onUpdate && onUpdate()
    }
  }

  const [addSerial, setAddSerial] = React.useState('')
  const [macs, setMacs] = React.useState([])
  const [features, setFeatures] = React.useState([])
  const [expireDate, setExpireDate] = React.useState(undefined)
  const [clear, setClear] = React.useState(false)
  const { notify } = useAppNotifications()
  const [showPool, setShowPool] = React.useState(false)

  const invalidSerial = React.useMemo(() => {
    return addSerial
      ? addSerial
          ?.split(/[\s,]+/)
          .map(val => val.trim())
          .find(val => !validMACaddress.test(val))
      : false
  }, [addSerial])

  const invalidCountOfSerial = React.useMemo(() => {
    return macs?.length > 100
  }, [macs])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const add = values => {
    const added = [
      ...new Set([
        ...(addSerial || values)
          .split(/[\s,]+/)
          .filter(txt => !!txt.trim() && validMACaddress.test(txt)),
        ...macs
      ])
    ]
    setMacs(added)
    setAddSerial('')
  }

  const readCsv = file => {
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.onload = function(r) {
      let result = r.target.result
      if (result) {
        add(result)
        setClear(val => !val)
      } else {
        const msg =
          'Something went wrong while processing the CSV file. Please try again.'
        notify(msg, null, null, 'Processing CSV networking file failed')
      }
    }
    reader.onerror = function(r) {
      setClear(val => !val)
      const msg =
        'Something went wrong while processing the CSV file. Please try again.'
      notify(msg, null, null, 'Processing CSV networking file failed')
    }
    reader.readAsText(file)
  }

  const tagValues = React.useMemo(
    () =>
      macs.map(mac => {
        return { color: 'green', value: mac }
      }),
    [macs]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Feature',
        accessor: 'displayLabel',
        Cell: ({ row }) => {
          const { displayLabel, status } = row.original.feature

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          const dateStyle = isExpired
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.danger}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : isExpiring
            ? {
                style: {
                  borderLeft: `5px solid ${theme.colors.warning}`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }
            : {
                style: {
                  borderLeft: `5px solid transparent`,
                  padding: '0.75rem',
                  margin: '-0.75rem'
                }
              }

          return (
            <div {...dateStyle}>
              <div>{displayLabel}</div>
            </div>
          )
        }
      },
      {
        Header: 'In Use / Allocated',
        accessor: 'totalAllotment',
        align: 'right',
        Cell: ({ row }) => {
          const { inUse, totalAllotment } = row.original
          const { isCardinal } = row.original.feature

          return (
            <>
              {isCardinal ? (
                <div
                  style={{
                    flex: 1
                  }}
                  data-tip={`${totalAllotment} licenses have been assigned to this pool, of which ${inUse} leases have been issued`}
                >
                  <UsageIndicator
                    assignedQuantity={inUse}
                    totalQuantity={totalAllotment}
                    short
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1
                  }}
                >
                  n/a
                </div>
              )}
            </>
          )
          //return <RightAlign>{isCardinal ? totalQuantity : 'n/a'}</RightAlign>
        }
      },
      {
        Header: 'Effective',
        accessor: 'startDate',
        width: 100,
        Cell: ({ row }) => {
          const { startDate } = row.original.feature

          return <small>{formatDate(startDate)}</small>
        }
      },
      {
        Header: 'Expiration',
        accessor: 'endDate',
        width: 100,
        Cell: ({ row }) => {
          const { endDate, status } = row.original.feature

          const isExpiring = status === LS_FEATURE_STATUS_EXPIRING
          const isExpired = status === LS_FEATURE_STATUS_EXPIRED

          return isExpired || isExpiring ? (
            <div
              data-tip={
                isExpired
                  ? 'This feature has expired'
                  : isExpiring
                  ? 'This feature will expire soon'
                  : null
              }
            >
              <Icon
                name='StatusWarning'
                color={isExpired ? theme.colors.danger : theme.colors.warning}
              />
              {formatExpirationDate(endDate)}
            </div>
          ) : (
            <small>{formatExpirationDate(endDate)}</small>
          )
        }
      }
    ],
    [theme]
  )

  return (
    <>
      {submitting && <ModalSpinner />}
      <StyledContainerForTooltip>
        <Text textStyle='label'>Lease expiration date</Text>
        &nbsp;
        <span
          data-for='modal-tooltip'
          data-tip={`If the Lease Expiry entered is greater than the License Pool Feature Expiry <br/> then the lease expiry would be limited up to Feature Expiry`}
        >
          <Icon
            name='StatusCircleInformation'
            color={theme.colors?.tooltip?.warning?.foreground}
          />
        </span>
      </StyledContainerForTooltip>
      <DatePicker
        onChange={val =>
          setExpireDate(
            moment(new Date(val))
              .endOf('day')
              .utc()
              .format()
          )
        }
        placeholderText='Select the expiration date'
        label=''
        minDate={new Date()}
      />

      <div style={{ marginTop: '1rem', marginBottom: '-1rem' }}>
        <StyledContainerForTooltip>
          <Text textStyle='label'>
            Enter or import the MAC addresses of the virtual machines used with
            this license server
          </Text>
          &nbsp;
          <span
            data-for='modal-tooltip'
            data-tip={`System will consider only unique MAC addresses upto 100.<br/>Each MAC address represents a unique license file with one lease count granted for the selected feature(s).`}
          >
            <Icon name='StatusCircleInformation' color={theme.colors?.brand} />
          </span>
        </StyledContainerForTooltip>
      </div>
      <AddContent>
        <Textbox
          className='nvl'
          value={addSerial}
          onChange={({ target: { value } }) => setAddSerial(value)}
          placeholder='Enter comma seperated MAC addresses(upto 100)'
          onKeyUp={({ key }) => key === 'Enter' && add()}
          valid={!invalidSerial || !invalidCountOfSerial}
          validationMessage={''}
        />
        <Button
          icon={{ name: 'ActionsAdd' }}
          variant='link'
          onClick={() => add()}
          disabled={!addSerial || invalidSerial}
        >
          Add MAC Address(es)
        </Button>

        <FileUpload
          label='Select file'
          variant='link'
          clearFile={clear}
          onChange={readCsv}
        />
      </AddContent>
      {invalidCountOfSerial || invalidSerial ? (
        <FormikError>
          {invalidCountOfSerial
            ? 'Count of MAC addresses must be less than or equal to 100'
            : invalidSerial
            ? `Values must be a valid MAC address`
            : ''}
        </FormikError>
      ) : null}

      <div style={{ marginTop: '0.5rem' }}>
        <Text textStyle='label'>
          {macs.length > 0 && macs.length <= 100
            ? `${macs.length} lease license file${
                macs.length > 1 ? 's' : ''
              } will be created`
            : macs.length > 100
            ? `Added MAC addresses (${macs.length})`
            : `No MAC addresses have been entered`}
          {macs.length > 0 && (
            <StyledInlineButton>
              &nbsp;
              <InlineButton
                variant='link'
                size='small'
                onClick={() => setMacs([])}
              >
                Clear All
              </InlineButton>
            </StyledInlineButton>
          )}
        </Text>

        <TagEditor
          disableInput
          values={tagValues}
          onChange={val => setMacs(val.map(v => v.value))}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Text textStyle='label'>Available features</Text>
        <ModalSelect
          multiSelect
          options={featureOptions}
          onChange={val => setFeatures(val || [])}
          value={features}
          placeholder='Select one or more features'
        />
        <div style={{ marginTop: '1rem' }}>
          <InlineButton variant='link' onClick={() => setShowPool(val => !val)}>
            {showPool ? 'Hide' : 'View'} detailed license pool information
          </InlineButton>
          {showPool && (
            <Block>
              <Table
                columns={columns}
                data={licensePools[0]?.licensePoolFeatures}
                disableSearch
                disableColumnHiding
                disablePagination
                disableFiltering
                disableSorting
                minHeight={1}
              />
            </Block>
          )}
        </div>
      </div>
      <div style={{ marginTop: '3rem' }}>
        <ModalButtons>
          <Button
            onClick={submit}
            disabled={
              macs.length === 0 ||
              invalidCountOfSerial ||
              features.length === 0 ||
              submitting
            }
          >
            generate
          </Button>
        </ModalButtons>
      </div>
    </>
  )
}

const StyledContainerForTooltip = styled.div`
  display: flex;
  align-items: center;

  span {
    vertical-align: middle;
    align-self: flex-end;
  }
`
const StyledInlineButton = styled.span`
  div {
    display: inline-block;
  }
`
