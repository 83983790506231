import React, { useState, useGlobal } from 'reactn'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import { useAppNotifications, useFetch } from 'common'
import { API_SERVICE_INSTANCE_MIGRATION_COMPLETE } from '../../api'
import { SessionExpired } from '..'
import {
  GLOBAL_UPGRADE_REFRESH,
  GLOBAL_UPGRADE_STATUS
} from '../../globalState'

export const AckUpgrade = () => {
  const [open, setOpen] = useState(false)
  const [, setMigrationStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_REFRESH)
  const { notify } = useAppNotifications()
  const { getData } = useFetch({
    endpoint: API_SERVICE_INSTANCE_MIGRATION_COMPLETE,
    actionLabel: 'Ack upgrade',
    method: 'PUT',
    SessionExpired: SessionExpired
  })

  const ack = async () => {
    const data = await getData()

    if (data) {
      notify(
        'Virtual appliance upgrade completed successfully',
        null,
        null,
        'Upgrade completed successfully'
      )
      setMigrationStatus(undefined)
      setRefresh(val => !val)
    }
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Ack Upgrade</Button>
      <Modal
        title='Confirm Upgrade'
        open={open}
        onClose={() => setOpen(false)}
        onPrimaryButtonClick={() => ack()}
        primaryButtonText='Confirm Upgrade'
      >
        <>
          This will mark the upgrade as complete and delete the old virtual
          appliance. Any changes done to the old virtual appliance after data
          migration step will be lost. The IP address of the current virtual
          appliance will be updated to the old virtual appliance's IP address.
        </>
      </Modal>
    </>
  )
}
