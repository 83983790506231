import React, { useMemo, useState, useGlobal, useEffect } from 'reactn'
import moment from 'moment'
import { saveAs } from 'file-saver'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Button from '@kaizen-ui/button'
import { Text } from '@kaizen-ui/complete'
import {
  Table,
  FormikError,
  useFetch,
  ModalSpinner,
  ModalButtons
} from 'common'
import { GLOBAL_ORG, GLOBAL_VIRTUAL_GROUP } from '../../globalState'
import {
  API_SERVICE_INSTANCE_TOKEN_CONFIG,
  API_SERVICE_INSTANCE_TOKEN_DOWNLOAD,
  formatServiceInstanceTokenConfig
} from '../../api'
import { SessionExpired } from '..'
import { NodeSection } from './NodeSection'

const StyledTabs = styled.div`
  overflow: auto;
  margin: 0.5rem;

  .content {
    display: block;
    padding: 1rem 0.5rem 0 0;
  }
`

const validationSchema = Yup.object({
  scopes: Yup.array().min(1, 'You must select at least 1 scope reference'),
  classRefs: Yup.array()
})
const initialValues = {
  scopes: [],
  classRefs: []
}

export const GenerateClientToken = ({ serviceInstance, server, onUpdate }) => {
  const { xid, high_availability_config } = serviceInstance || {}
  const [selectionList, setSelectionList] = useState([])
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [pageLoading, setPageLoading] = useState(true)
  const [selected, setSelected] = useState([])
  const [selectedFcs, setSelectedFcs] = useState([])
  const [scopes, setScopes] = useState([])
  const [classRefs, setClassRefs] = useState([])
  const [, setFilter] = useState({})
  const [, setFcFilter] = useState({})

  const [nodeList, setNodeList] = useState([])

  useEffect(() => {
    if (high_availability_config?.config?.nodeList) {
      setNodeList(
        high_availability_config?.config?.nodeList?.sort((a, b) => {
          if (a.role < b.role) {
            return -1
          }
          if (a.role > b.role) {
            return 1
          }
          return 0
        })
      )
    }
  }, [high_availability_config])

  const { getData, loading, error, abort, lastUpdate } = useFetch({
    endpoint: API_SERVICE_INSTANCE_TOKEN_CONFIG(orgId, vgId),
    actionLabel: 'Get service instance token references',
    SessionExpired: SessionExpired,
    normalizer: data => formatServiceInstanceTokenConfig(data, server?.id)
  })
  const {
    getData: downloadToken,
    loading: submitting,
    abort: abortDownload
  } = useFetch({
    endpoint: API_SERVICE_INSTANCE_TOKEN_DOWNLOAD,
    actionLabel: `Download client token for instance ${xid}`,
    method: 'POST',
    SessionExpired: SessionExpired
  })

  const submit = async values => {
    const body = {
      scopeReferenceList: values.scopes.map(option => option.value),
      fulfillmentClassReferenceList: values.classRefs.map(
        option => option.value
      ),
      addressTypeSelections: selectionList
    }

    const data = await downloadToken({ body })
    if (data?.messengerToken) {
      const blob = new Blob([data?.messengerToken], {
        type: 'text/plain'
      })
      saveAs(
        blob,
        `client_configuration_token_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.tok`
      )
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Server Name',
        accessor: 'displayName'
      },
      {
        Header: 'Reference',
        accessor: 'value'
      }
    ],
    []
  )
  const classColumns = useMemo(
    () => [
      {
        Header: 'Condition Name',
        accessor: 'displayName'
      },
      {
        Header: 'Server Name',
        accessor: 'serverName'
      },
      {
        Header: 'Reference',
        accessor: 'value'
      }
    ],
    []
  )

  useEffect(() => {
    const getRefs = async () => {
      const data = await getData({
        headers: {
          'X-NV-SERVICE-INSTANCE-ID': xid
        }
      })

      if (data) {
        const { scopes, classRefs } = data
        setScopes(scopes)
        setClassRefs(classRefs)
        setPageLoading(false)
      }
    }
    getRefs()

    return () => {
      abort()
      abortDownload()
    }
  }, [vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  const onSelectionChange = selection => {
    setSelectionList(existingList => [
      ...existingList.filter(sel => sel.role !== selection.role),
      selection
    ])
  }
  return (
    <>
      {submitting && <ModalSpinner />}
      <StyledTabs>
        <Tabs>
          <Tab id={'1'} title='Server address preferences'>
            <Text textStyle={'p1'}>
              Following address selections will be included in the client
              configuration token and will be used by the clients to connect to
              the license server.
            </Text>

            {nodeList.map((node, index) => {
              return (
                <div key={index} onClick={e => e.stopPropagation()}>
                  <NodeSection
                    node={node}
                    index={index}
                    onSelectionChange={onSelectionChange}
                    selection={selectionList.find(
                      selection => selection?.role === node.role
                    )}
                  />
                </div>
              )
            })}

            {formik.touched.scopes && formik.errors.scopes && (
              <FormikError>{formik.errors.scopes}</FormikError>
            )}
          </Tab>
          <Tab id={'2'} title='Scope references'>
            <>
              <Table
                columns={columns}
                data={scopes}
                disabled={submitting}
                loading={pageLoading}
                fetching={loading}
                error={error}
                label={'scope references'}
                dataId='value'
                //refresh={() => setRefresh(val => !val)}
                lastUpdate={lastUpdate}
                onFilter={setFilter}
                //globalSearch={search}
                selectedRows={selected}
                onSelect={values => {
                  setSelected(values)
                  formik.setFieldTouched('scopes', true)
                  formik.setFieldValue('scopes', values)
                }}
                disablePageSizeSelection
                disableColumnHiding
                disableExporting
                initialPageSize={5}
                minHeight={1}
              />
              {formik.touched.scopes && formik.errors.scopes && (
                <FormikError>{formik.errors.scopes}</FormikError>
              )}
            </>
          </Tab>
          <Tab id={'3'} title='Fulfillment class references'>
            <>
              <Table
                columns={classColumns}
                data={classRefs}
                disabled={submitting}
                loading={pageLoading}
                fetching={loading}
                error={error}
                label={'class references'}
                dataId='value'
                //refresh={() => setRefresh(val => !val)}
                lastUpdate={lastUpdate}
                onFilter={setFcFilter}
                //globalSearch={search}
                selectedRows={selectedFcs}
                onSelect={values => {
                  setSelectedFcs(values)
                  formik.setFieldTouched('classRefs', true)
                  formik.setFieldValue('classRefs', values)
                }}
                disablePageSizeSelection
                disableColumnHiding
                disableExporting
                initialPageSize={5}
                minHeight={1}
              />
              {formik.touched.scopes && formik.errors.scopes && (
                <FormikError>{formik.errors.scopes}</FormikError>
              )}
            </>
          </Tab>
          {/* <Tab id={3} title='Preview changes' disabled={!formik.dirty}>
      <PreviewManageFeatures formik={formik} />
    </Tab> */}
        </Tabs>
      </StyledTabs>
      <ModalButtons>
        <Button
          disabled={!formik.isValid || submitting}
          onClick={formik.handleSubmit}
          icon={{ name: 'ActionsDownload' }}
        >
          Download Client Configuration Token
        </Button>
      </ModalButtons>
    </>
  )
}
