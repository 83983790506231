import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useGlobal
} from 'reactn'
import { ModalSpinner, PageHeader, useFetch } from 'common'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import Banner from '@kaizen-ui/banner'
import Modal from '@kaizen-ui/modal'
import {
  API_SERVICE_INSTANCES,
  HIGH_AVAILABILITY_NODE_ROLE,
  HIGH_AVAILABILITY_STATE
} from '../../api'
import { SessionExpired } from '../../Components'
import { ServiceInstanceProperties } from './ServiceInstanceProperties'
import { Health } from './Health'
import { ServiceInstanceActions } from './ServiceInstanceActions'
import { ConfigureHighAvailability } from './ConfigureHighAvailability'
import { HighAvailabilityInProgress } from './HighAvailabilityInProgress'
import { GLOBAL_USER } from '../../globalState'
import { useLicenseServer } from '../../hooks'

const StyledBanner = styled.div`
  svg {
    fill: ${({ theme }) => theme.colors.red700};
  }
`

export const ServiceInstance = () => {
  const theme = useContext(KaizenThemeContext)
  const [pageLoading, setPageLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [haConfigOpen, setHaConfigOpen] = useState(false)
  const [si, setSi] = useState(undefined)
  const [user] = useGlobal(GLOBAL_USER)
  const { isPureContainer } = user || {}
  const { licenseServer } = useLicenseServer({ autoLoad: true })
  const { isSimpleMode } = licenseServer || {}

  const { getData, loading, abort } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Get service instance',
    SessionExpired: SessionExpired
  })
  const haState = useMemo(() => si?.high_availability_config?.mode, [si])
  const primaryNode = useMemo(
    () =>
      si?.high_availability_config?.config?.nodeList?.find(
        node =>
          node.role?.toLowerCase() ===
          HIGH_AVAILABILITY_NODE_ROLE.PRIMARY.toLowerCase()
      ),
    [si]
  )
  const secondaryNode = useMemo(
    () =>
      si?.high_availability_config?.config?.nodeList?.find(
        node =>
          node.role?.toLowerCase() !==
          HIGH_AVAILABILITY_NODE_ROLE.PRIMARY.toLowerCase()
      ),
    [si]
  )

  /*const secondaryNode = {
    fqdn: 'dhcp-10-24-129-217.nvidia.com',
    ip_v6_address: null,
    mac_address: null,
    network_location: '10.24.129.217',
    role: 'Unknown',
    status: {}
  }*/
  const currentLocation = window?.location?.hostname
  //   const isSecondaryNode = useMemo(() => {
  //     return (
  //       secondaryNode?.network_location === currentLocation ||
  //       secondaryNode?.ip_v6_address === currentLocation ||
  //       secondaryNode?.fqdn === currentLocation
  //     )
  //   }, [secondaryNode, currentLocation])
  const currentNode = useMemo(
    () => si?.high_availability_config?.config?.nodeList?.[0],
    [si]
  )

  useEffect(() => {
    document.title = 'DLS - Service Instance'
    const getSi = async () => {
      const data = await getData()
      if (data) {
        setPageLoading(false)
        setSi(data)
      }
    }
    getSi()

    return () => {
      abort()
    }
  }, [refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  if (pageLoading) return <ModalSpinner />

  return (
    <>
      <PageHeader
        title='Service Instance Details'
        //helpUrl={DOCS_LICENSE_SERVER}
        //tip='Get help with license servers'
        subTitle='Manage the DLS service instance and customize to your environment'
      >
        {si && (
          <ButtonGroup>
            <Button
              type='secondary'
              onClick={() => setRefresh(val => !val)}
              icon={{ name: 'CloudRefresh' }}
            >
              Refresh
            </Button>
            <ServiceInstanceActions
              serviceInstance={si}
              onUpdate={() => setRefresh(val => !val)}
              isSimpleMode={isSimpleMode}
            />
          </ButtonGroup>
        )}
      </PageHeader>

      <ServiceInstanceProperties
        loading={loading}
        si={si}
        haState={haState}
        openHaConfig={() => setHaConfigOpen(true)}
        isSimpleMode={isSimpleMode}
      />

      <div style={{ marginTop: '1rem' }}>
        {(haState === HIGH_AVAILABILITY_STATE.STANDALONE ||
          haState === HIGH_AVAILABILITY_STATE.CLUSTERED) && (
          <Health
            primary={primaryNode}
            secondary={secondaryNode}
            loading={loading}
            currentLocation={currentLocation}
            onUpdate={() => setRefresh(val => !val)}
            isPureContainer={isPureContainer}
            isClustered={haState === HIGH_AVAILABILITY_STATE.CLUSTERED}
          />
        )}
        {haState === HIGH_AVAILABILITY_STATE.UNCONFIGURED && (
          <StyledBanner theme={theme}>
            <Banner
              title='High availability is not configured'
              status='passive'
              icon={{
                name: 'StatusWarning',
                size: 48
              }}
            >
              This instance needs to be configured either as STANDALONE or
              CLUSTERED
            </Banner>
          </StyledBanner>
        )}
        {haState === HIGH_AVAILABILITY_STATE.IN_PROGRESS && (
          <HighAvailabilityInProgress
            onComplete={() => setRefresh(val => !val)}
          />
        )}
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Configure High Availability'
          //subtitle='Create a configuration token for client access to server resources'
          className='autow'
          open={haConfigOpen}
          onClose={() => setHaConfigOpen(false)}
        >
          {haConfigOpen && (
            <ConfigureHighAvailability
              ip={currentNode?.network_location}
              ipv6={currentNode?.ip_v6_address}
              fqdn={currentNode?.fqdn}
              onUpdate={() => {
                setHaConfigOpen(false)
                setRefresh(val => !val)
              }}
              haState={haState}
            />
          )}
        </Modal>
      </div>
    </>
  )
}
