import React from 'react'
import { Button, Result, Text } from '@kaizen-ui/complete'
import { ModalButtons, useAppNotifications, useFetch } from 'common'
import { API_SERVICE_INSTANCES } from '../../api'

export const ToggleInstantClone = ({ serviceInstance, onUpdate }) => {
  const { notify } = useAppNotifications()
  const { getData: sendRequest, loading: submitting, error } = useFetch({
    endpoint: API_SERVICE_INSTANCES,
    actionLabel: 'Edit service instance',
    method: 'PATCH'
  })
  const submit = async () => {
    const { name, description, instantCloneSupport, xid } = serviceInstance

    const body = {
      name,
      description,
      instantCloneSupport: !instantCloneSupport
    }
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': xid }
    const result = await sendRequest({ body, headers })
    if (result) {
      const msg = `Service Instance ${name} updated successfully!`
      notify(
        msg,
        null,
        null,
        `${instantCloneSupport ? 'Enabled' : 'Disabled'} instant clone support`
      )
      onUpdate && onUpdate()
    }
  }

  const resultProps = React.useMemo(() => {
    if (error) return { status: 'error', subTitle: error }
    else if (submitting) {
      return {
        status: 'loading',
        subTitle: `${
          serviceInstance?.instantCloneSupport ? 'Enabling' : 'Disabling'
        } instant clone support`
      }
    } else {
      return {
        status: 'info',
        title: `${
          serviceInstance?.instantCloneSupport ? 'Disable' : 'Enable'
        } instant cloning support?`,
        children: (
          <Text textStyle='p1'>
            Enable this option if you are planning on licensing Instant Clone
            Desktop Pools with this Service Instance. When enabled, a lease
            borrow request from a client machine will check for any duplicated
            MAC Addresses already leased on this Service Instance and clear
            them.
          </Text>
        )
      }
    }
  }, [submitting, error, serviceInstance])

  return (
    <>
      <Result {...resultProps} />
      <ModalButtons>
        <Button onClick={submit} disabled={submitting}>
          Toggle instance clone support
        </Button>
      </ModalButtons>
    </>
  )
}
