import React, { useContext, useGlobal } from 'reactn'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { MigrateData } from './MigrateData'
import { BlockContents, BlockSection, BlockTitle, Step } from './Upgrade'
import { MIGRATION_STATUS } from '../../api'
import { GLOBAL_UPGRADE_STATUS } from '../../globalState'
import { AckUpgrade } from '../../Components'

export const OnnlineUpgrade = () => {
  const theme = useContext(KaizenThemeContext)
  const [migrationStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)

  return (
    <BlockContents>
      <BlockSection>
        <BlockTitle theme={theme}>
          <Step>Step 1 -</Step>
          Set up a new virtual appliance
        </BlockTitle>
        <div>
          Install a new version of the virtual appliance on a virtual machine.
        </div>
      </BlockSection>
      <BlockSection>
        <BlockTitle theme={theme}>
          <Step>Step 2 -</Step>
          Migrate data
        </BlockTitle>
        For migrating license configuration of this virtual appliance to the new
        virtual appliance, enter the IP address or FQDN of the newly installed
        virtual appliance and click migrate.
        <MigrateData />
      </BlockSection>
      <BlockSection>
        <BlockTitle theme={theme}>
          <Step>Step 3 -</Step>
          Acknowledge the upgrade
        </BlockTitle>
        <div>
          Go to the new virtual appliance&#39;s <b>Maintenance</b> page, and
          click <b>Ack Upgrade</b> to complete the upgrade.
        </div>
        {migrationStatus?.isTargetNode &&
          migrationStatus?.state ===
            MIGRATION_STATUS.DATA_TRANSFER_COMPLETED && <AckUpgrade />}
      </BlockSection>
    </BlockContents>
  )
}
