import {
  Result,
  KaizenThemeContext,
  Textbox,
  Password,
  Button,
  ButtonGroup
} from '@kaizen-ui/complete'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ModalButtons, useAppNotifications, useFetch } from 'common'
import { API_EMAIL_ALERTS_SMTP } from '../../../api'
import { SessionExpired } from '../../../Components'

const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.result.border};
  background: ${({ theme }) => theme.colors.result.background};
  display: flex;

  .form {
    background: ${({ theme }) =>
      theme.darkMode ? theme.colors.pageBackground : theme.colors.lightGray000};
    padding: 1rem;
    border-right: 1px solid ${({ theme }) => theme.colors.result.border};
    min-width: 25rem;
  }
`
const StyledResult = styled(Result)`
  border: none;
`
const StyledPassword = styled(Password)`
  svg {
    margin-top: -2rem;
    margin-right: 0.5rem;
  }
`

const validationSchema = Yup.object({
  host: Yup.string().required('You must enter the SMTP server hostname'),
  username: Yup.string().required('You must enter the SMTP server username'),
  password: Yup.string().required('You must enter the SMTP server password')
})

export const ConfigureSmtp = ({ smtp, onUpdate, onCancel }) => {
  const theme = React.useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const { getData: sendRequest, loading } = useFetch({
    endpoint: API_EMAIL_ALERTS_SMTP(),
    actionLabel: 'Update SMTP configuration',
    SessionExpired: SessionExpired,
    method: 'PUT'
  })

  const initialValues = {
    host: smtp?.host || '',
    username: smtp?.username || '',
    password: ''
  }

  const onSubmit = async values => {
    const { host, username, password } = formik.values

    const body = {
      host,
      username,
      password
    }
    const result = await sendRequest({ body })

    if (result) {
      notify(
        `Successfully configured SMTP setting`,
        null,
        null,
        'Updated SMTP configuration'
      )
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  })

  return (
    <Outer theme={theme}>
      <div className='form'>
        <Textbox
          id='host'
          name='host'
          label='Host'
          className='nvl'
          placeholder='Enter the hostname of the SMTP server'
          value={formik.values.host}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.host && formik.errors.host}
        />
        <Textbox
          id='username'
          name='username'
          label='Username'
          className='nvl'
          placeholder='Enter the username for the SMTP server'
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.username && formik.errors.username}
        />
        <StyledPassword
          id='password'
          name='password'
          label='Password'
          className='nvl'
          inputMode=''
          placeholder='Enter the password for the SMTP server'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.password && formik.errors.password}
          showVisibilityToggle={
            formik.values.password !== formik.initialValues.password
          }
        />
        <ModalButtons>
          <ButtonGroup>
            <Button type='secondary' onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={formik.handleSubmit} disabled={loading}>
              Configure SMTP
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </div>
      <StyledResult
        status={loading ? 'loading' : 'warning'}
        title={
          loading
            ? 'Updating SMTP configuration'
            : 'SMTP configuration has not been completed'
        }
      />
    </Outer>
  )
}
