import { useEffect, useMemo, useGlobal } from 'reactn'
import { useFetch } from 'common'
import {
  API_SERVICE_INSTANCE_MIGRATION_STATUS,
  MIGRATION_STATUS
} from '../../api'
import { SessionExpired } from '../../Components'
import { useAuth } from '..'
import {
  GLOBAL_UPGRADE_REFRESH,
  GLOBAL_UPGRADE_STATUS
} from '../../globalState'

// const mock = {
//   createdAt: '2021-02-22T08:45:06.610434Z',
//   isTargetNode: true,
//   lastUpdatedAt: '2021-02-22T08:45:06.610434Z',
//   //message: MIGRATION_STATUS_MSG.target_data_transfer_completed,
//   state: MIGRATION_STATUS.VALIDATED,
//   targetIP: '10.24.130.114',
//   sourceIP: '10.24.130.167',
//   xid: '25b5c93c-1c6b-45b6-9a95-50ad7632addd'
// }

export function useUpgradeNotifier() {
  //const [migrationStatus, setMigrationStatus] = useState(undefined)
  const [migrationStatus, setMigrationStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const [refresh] = useGlobal(GLOBAL_UPGRADE_REFRESH)
  const { user } = useAuth()
  const pendingMigration = useMemo(
    () =>
      !!migrationStatus?.state &&
      migrationStatus?.state !== MIGRATION_STATUS.COMPLETED,
    [migrationStatus]
  )
  const { getData, abort } = useFetch({
    endpoint: API_SERVICE_INSTANCE_MIGRATION_STATUS,
    actionLabel: 'Check migration status',
    SessionExpired: SessionExpired
    //normalizer: () => mock
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.DATA_TRANSFER_INITIATED }
    // }
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.DATA_TRANSFER_COMPLETED }
    // }
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.COMPLETED }
    // }
    // normalizer: () => {
    //   return { ...mock, state: MIGRATION_STATUS.FAILED }
    // }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      getStatus()
    }, 5000)
    const getStatus = async () => {
      if (!user) {
        clearInterval(interval)
        return
      }
      const data = await getData()

      if (!data || !data.state) {
        setMigrationStatus(undefined)
        clearInterval(interval)
        return
      }

      const { state } = data
      setMigrationStatus(data)
      if (
        state === MIGRATION_STATUS.FAILED ||
        state === MIGRATION_STATUS.DATA_TRANSFER_COMPLETED ||
        state === MIGRATION_STATUS.COMPLETED
      ) {
        clearInterval(interval)
      }
    }
    getStatus()

    return () => {
      clearInterval(interval)
      abort()
    }
  }, [user, refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    migrationStatus,
    pendingMigration
  }
}
