import React, {
  //useContext,
  useGlobal,
  useState
} from 'reactn'
import moment from 'moment'
import { saveAs } from 'file-saver'
//import { KaizenThemeContext } from '@kaizen-ui/styles'
import Button from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import ActionMenu, {
  ActionMenuItem,
  ActionMenuDivider
} from '@kaizen-ui/action-menu'
import { GLOBAL_SERVER_ID } from '../../globalState'
import { GenerateClientToken, SessionExpired } from '../../Components'
import { API_SERVICE_INSTANCE_IDENTITY_TOKEN } from '../../api'
import { useFetch, useAppNotifications } from 'common'
import { EditServiceInstance } from './EditServiceInstance'
import { useAuth } from '../../hooks'
import { PreRegisterServiceInstance } from './PreRegisterServiceInstance'
import { ToggleInstantClone } from './ToggleInstantClone'

export const ServiceInstanceActions = ({
  serviceInstance,
  onUpdate,
  isSimpleMode = false
}) => {
  //const theme = useContext(KaizenThemeContext)
  const { user } = useAuth()
  const { isMarkedPreReg } = user || {}
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isPreRegOpen, setPreRegister] = useState(false)
  const [instantCloneOpen, setInstantCloneOpen] = useState(false)
  const [clientTokenOpen, setClientTokenOpen] = useState(false)
  /*  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false)
  const [isNwShareModalOpen, setNwShareModalOpen] = useState(false)
  const [isSSLConfigModalOpen, setSSLConfigModalOpen] = useState(false)
  const [isNTPModalOpen, setNTPModalOpen] = useState(false)*/
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const {
    download,
    loading: downloadingIdentity
  } = useServiceInstanceTokenDownloader(serviceInstance)

  return (
    <>
      <ActionMenu
        width={250}
        parentElement={
          <div data-tip='Select actions available for this service instance'>
            <Button
              icon={{
                name: 'ActionsDrawer'
              }}
              className='bright'
            >
              Actions
            </Button>
          </div>
        }
        position='top-right'
        trigger='click'
      >
        <React.Fragment key='.0'>
          <ActionMenuItem
            label='Download DLS Instance Token'
            icon={{ name: 'ActionsDownload' }}
            disabled={downloadingIdentity || isMarkedPreReg}
            onClick={download}
          />
          {!isSimpleMode ? (
            <ActionMenuItem
              label='Toggle Instant Clone Support'
              icon={{ name: 'ActionsEdit' }}
              onClick={() => {
                setInstantCloneOpen(true)
              }}
            />
          ) : null}
          <ActionMenuItem
            label='Edit'
            icon={{ name: 'ActionsEdit' }}
            onClick={() => {
              setEditModalOpen(true)
            }}
          />

          <ActionMenuItem
            label='Pre-Register Service Instance'
            icon={{ name: 'EditCheckList' }}
            onClick={() => {
              setPreRegister(true)
            }}
            disabled={serverId}
          />

          {/*  {!isPureContainer ? (
            <ActionMenuItem
              label='Log Archive Settings'
              icon={{ name: 'ConnectionServerNetwork2' }}
              onClick={() => {
                setNwShareModalOpen(true)
              }}
            />
          ) : null}*/}

          {/* <ActionMenuItem
            label='SSL Configuration'
            icon={{ name: 'StatusShieldCheck' }}
            onClick={() => {
              setSSLConfigModalOpen(true)
            }}
            name={'action-configure-ssl'}
          />*/}

          {/*{!isPureContainer ? (
            <ActionMenuItem
              label='NTP Server Configuration'
              icon={{ name: 'TimeClock' }}
              onClick={() => {
                setNTPModalOpen(true)
              }}
              name={'action-ntp-server'}
            />
          ) : null}*/}

          {!isSimpleMode ? (
            <>
              <ActionMenuDivider />
              <ActionMenuItem
                label='Generate client config token'
                icon={{ name: 'ComputerDisk3' }}
                disabled={!serverId}
                onClick={() => setClientTokenOpen(true)}
              />
            </>
          ) : null}

          {/* <ActionMenuItem
            label='Settings'
            icon={{ name: 'SettingsCog' }}
            disabled={!serverId}
            onClick={() => setSettingsModalOpen(true)}
          />*/}
        </React.Fragment>
      </ActionMenu>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Edit service instance'
          open={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
        >
          {isEditModalOpen && (
            <EditServiceInstance
              serviceInstance={serviceInstance}
              onUpdate={() => {
                setEditModalOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Toggle instant clone support'
          open={instantCloneOpen}
          onClose={() => setInstantCloneOpen(false)}
        >
          {instantCloneOpen && (
            <ToggleInstantClone
              serviceInstance={serviceInstance}
              onUpdate={() => {
                setInstantCloneOpen(false)
                onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Pre-register DLS service instance'
          open={isPreRegOpen}
          onClose={() => setPreRegister(false)}
        >
          {isPreRegOpen && (
            <PreRegisterServiceInstance
              serviceInstance={serviceInstance}
              onUpdate={() => {
                setPreRegister(false)
              }}
            />
          )}
        </Modal>
      </div>
      {/* <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Service Instance Settings'
          //subtitle='Create a configuration token for client access to server resources'
          className='autow'
          open={isSettingsModalOpen}
          onClose={() => setSettingsModalOpen(false)}
        >
          {isSettingsModalOpen && (
            <ServiceInstanceSettings
              serviceInstance={serviceInstance}
              onUpdate={() => setSettingsModalOpen(false)}
            />
          )}
        </Modal>
      </div>*/}
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Generate Client Configuration Token'
          subtitle='Create a configuration token for client access to server resources'
          //className='noScroll custom'
          //size='small'
          size='large'
          backgroundClose
          open={clientTokenOpen}
          onClose={() => setClientTokenOpen(false)}
        >
          {clientTokenOpen && (
            <GenerateClientToken
              serviceInstance={serviceInstance}
              onUpdate={() => setClientTokenOpen(false)}
            />
          )}
        </Modal>
      </div>
      {/*<div onClick={e => e.stopPropagation()}>
        <Modal
          title='Network Share Configuration for Log Archival'
          subtitle='Configure a network share for this instance. The logs will be archived
        periodically at the configured location.'
          open={isNwShareModalOpen}
          onClose={() => setNwShareModalOpen(false)}
          className='autow'
        >
          {isNwShareModalOpen && (
            <LogArchivalSetting
              onUpdate={() => {
                setNwShareModalOpen(false)
                //onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>*/}

      {/*<div onClick={e => e.stopPropagation()}>
        <Modal
          title='Network Time Protocol (NTP) Server Configuration'
          subtitle='Configure your network settings to use NTP server(s)'
          open={isNTPModalOpen}
          onClose={() => setNTPModalOpen(false)}
          //size='large'
        >
          {isNTPModalOpen && (
            <NTPServerConfig
              onUpdate={() => {
                setNTPModalOpen(false)
                //onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>*/}

      {/*<div onClick={e => e.stopPropagation()}>
        <Modal
          title='SSL Certificate Configuration'
          subtitle='Install your SSL certificate on this DLS instance.'
          open={isSSLConfigModalOpen}
          onClose={() => setSSLConfigModalOpen(false)}
          className='autow noScroll'
        >
          {isSSLConfigModalOpen && (
            <SSLConfiguration
              onUpdate={() => {
                setSSLConfigModalOpen(false)
                //onUpdate && onUpdate()
              }}
            />
          )}
        </Modal>
      </div>*/}
    </>
  )
}

export function useServiceInstanceTokenDownloader(serviceInstance) {
  const { notify } = useAppNotifications()
  const { getData: downloadToken, loading, abort, error } = useFetch({
    endpoint: API_SERVICE_INSTANCE_IDENTITY_TOKEN,
    actionLabel: `Download DLS identity token`,
    SessionExpired: SessionExpired
  })

  const submit = async () => {
    const data = await downloadToken({
      headers: { 'X-NV-SERVICE-INSTANCE-ID': serviceInstance?.xid }
    })
    if (data?.identityToken) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'text/plain'
      })
      saveAs(
        blob,
        `dls_instance_token_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.tok`
      )
    } else {
      notify('Unable to parse token', null, true)
    }
  }

  return {
    download: submit,
    loading,
    abort,
    error
  }
}
