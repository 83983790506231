import React, { useContext } from 'react'
import { PageHeader } from 'common'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
//import Button, { ButtonGroup } from '@kaizen-ui/button'
import Card from '@kaizen-ui/card'
import { ROUTE_SET_PASSWORD, ROUTE_UPGRADE } from '../../Components'

const Row = styled.div`
  display: flex;

  .card-title {
    color: ${({ theme }) => theme.colors.textbox.normal.foreground};
  }
  > div + div {
    margin-left: 1rem;
  }
`
export const Setup = () => {
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()

  React.useEffect(() => {
    document.title = 'DLS - Setup'
  }, [])

  return (
    <>
      <PageHeader
        title='Virtual Appliance Setup'
        //helpUrl={DOCS_LICENSE_SERVER}
        //tip='Get help with license servers'
        subTitle='Choose if this virtual appliance will be used as a new installation or to upgrade an existing one'
      />
      <Row theme={theme}>
        <Card
          headerEnabled
          headerIcon={{ name: 'DatabaseAdd' }}
          title='First time setup'
          childrenMinLines={2}
          primaryFooterAction={{
            label: 'New Installation',
            onClick: () => history.push(ROUTE_SET_PASSWORD)
          }}
        >
          <span style={{ color: theme.colors.lightGray500 }}>
            Setting up the virtual appliance for the first time?
          </span>
        </Card>
        <Card
          headerEnabled
          headerIcon={{ name: 'DatabaseStar' }}
          title='Upgrade existing'
          childrenMinLines={2}
          primaryFooterAction={{
            label: 'Upgrade',
            onClick: () => history.push(ROUTE_UPGRADE)
          }}
        >
          <span style={{ color: theme.colors.lightGray500 }}>
            Upgrading from an older virtual appliance version?
          </span>
        </Card>
      </Row>
    </>
  )
}
