import React, { useMemo, useContext, useState } from 'react'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import Text from '@kaizen-ui/text'
import Button from '@kaizen-ui/button'
import { LS_STATUS_DISABLED, LS_STATUS_ENABLED } from '../../api'
import { LicenseServerActions, ROUTE_DASHBOARD } from '../../Components'
import ReactTooltip from 'react-tooltip'
import { useHistory } from 'react-router'

const SERVER_ACTIONS = {
  STATUS: 'status',
  BIND: 'bind',
  EXPRESS: 'expressCls',
  REGISTER_BIND: 'registerDls'
}

const List = styled.div`
  //background: black;
  display: inline-flex;
  flex-direction: column;
`
const SectionHeader = styled.div`
  margin-top: 1rem;
`
export const LicenseServerOverview = ({ server, onTabChange, onUpdate }) => {
  const { name, status } = server
  const theme = useContext(KaizenThemeContext)
  const history = useHistory()
  const pools = useMemo(() => server?.licensePools || [], [server])
  const conditions = useMemo(() => server?.fulfillmentConditions || [], [
    server
  ])
  const poolsDisabled = pools.filter(pool => pool.status === LS_STATUS_DISABLED)
  const conditionsDisabled = conditions.filter(
    c => c.status === LS_STATUS_DISABLED
  )
  const unusedPools = useMemo(() => {
    return pools.filter(p => p.isUnbound === 'True')
  }, [pools])
  const features = server?.licenseServerFeatures || []
  const featuresInOverage = features.filter(feature => feature.overageCount > 0)
  const featuresUnallocated = features.filter(
    feature => feature.inUseQuantity === 0
  )
  const isEnabled = status === LS_STATUS_ENABLED
  const [actionOpen, setActionOpen] = useState(undefined)

  const clearAction = () => {
    onUpdate()
    setActionOpen(undefined)
  }

  return (
    <>
      <List>
        {/** server issues
         *   unbound = not bound to a service instance,
         *   status = if deployed to cloud, display enabled status
         *   not installed = bound to instance but not deployed
         *   deployed to dls = is installed on dls
         */}
        <SectionHeader>
          <Text textStyle='h3' color={theme.colors.textbox.placeholder}>
            ABOUT THIS SERVER
          </Text>
        </SectionHeader>
        <OverviewItem
          icon={{
            name: 'ConnectionServer',
            color: isEnabled ? theme.colors.brand : theme.colors.red500,
            size: 'large'
          }}
          text={
            isEnabled
              ? `License server ${name} is enabled and will serve leases, you can not make changes while the server is enabled`
              : `License server ${name} is disabled and will not serve leases, you can make changes while the server is disabled`
          }
          inline
        >
          <Button
            type='secondary'
            size='small'
            icon={{
              name: isEnabled ? 'PlaybackCirclePause' : 'PlaybackCirclePlay'
            }}
            onClick={() => setActionOpen({ action: SERVER_ACTIONS.STATUS })}
          >
            {isEnabled ? 'Disable server' : 'Enable server'}
          </Button>
        </OverviewItem>

        {/** server feature issues
         *   overrage = features that have exceeded the allowed count
         *   unallocated = features that have not been allocated to a pool
         */}

        {(featuresInOverage.length > 0 || featuresUnallocated.length > 0) && (
          <SectionHeader>
            <Text textStyle='h3' color={theme.colors.textbox.placeholder}>
              ABOUT FEATURES
            </Text>
          </SectionHeader>
        )}
        {featuresInOverage.length > 0 && (
          <OverviewItem
            inline
            icon={{
              name: 'AwardsFlag1',
              color: theme.colors.orange500,
              size: 'large',
              variant: 'solid'
            }}
            text={`There ${featuresInOverage.length === 1 ? ' is' : ' are'} ${
              featuresInOverage.length
            } server feature${
              featuresInOverage.length === 1 ? '' : 's'
            } in overage, if this happens frequently you should consider allocating additional licenses`}
            onClick={() => {
              const route = `${ROUTE_DASHBOARD}?tab=2&search=overage`
              history.replace(route)
              onTabChange(2)
              //setFilter('overage')
              ReactTooltip.hide()
            }}
          >
            <Button
              type='secondary'
              size='small'
              icon={{ name: 'ActionsFilter', variant: 'solid' }}
              onClick={() => {
                const route = `${ROUTE_DASHBOARD}?tab=2&filters=isOverage%3Doverage`
                history.replace(route)
                onTabChange(2)
                //setFilter('overage')
                ReactTooltip.hide()
              }}
            >
              View features in overage
            </Button>
          </OverviewItem>
        )}
        {featuresUnallocated.length > 0 && (
          <OverviewItem
            inline
            icon={{
              name: 'AwardsFlag1',
              color: theme.colors.orange300,
              size: 'large',
              variant: 'solid'
            }}
            text={`There ${featuresUnallocated.length === 1 ? ' is' : ' are'} ${
              featuresUnallocated.length
            } server feature${
              featuresUnallocated.length === 1 ? '' : 's'
            } not allocated to any pool`}
          >
            <Button
              type='secondary'
              size='small'
              icon={{ name: 'ActionsFilter', variant: 'solid' }}
              onClick={() => {
                const route = `${ROUTE_DASHBOARD}?tab=2&filters=isUnallocated%3Dunallocated`
                history.replace(route)
                onTabChange(2)
                //setFilter('unallocated')
                ReactTooltip.hide()
              }}
            >
              View unallocated features
            </Button>
          </OverviewItem>
        )}

        {/** Pool issues
         *   disabled = disabled, will not serve
         *   unused = pools that have not be bound to a fulfillment condition
         */}

        {(poolsDisabled.length > 0 || unusedPools.length > 0) && (
          <SectionHeader>
            <Text textStyle='h3' color={theme.colors.textbox.placeholder}>
              ABOUT LICENSE POOLS
            </Text>
          </SectionHeader>
        )}
        {poolsDisabled.length > 0 && (
          <OverviewItem
            inline
            icon={{
              name: 'ConnectionServer',
              color: theme.colors.orange700,
              size: 'large'
            }}
            text={`There ${poolsDisabled.length === 1 ? ' is' : ' are'} ${
              poolsDisabled.length
            } disabled license pool${
              poolsDisabled.length === 1 ? '' : 's'
            }, no features will be served from this pool`}
          >
            <Button
              type='secondary'
              size='small'
              icon={{ name: 'ActionsFilter', variant: 'solid' }}
              onClick={() => {
                const route = `${ROUTE_DASHBOARD}?tab=3&filters=status%3DDISABLED`
                history.replace(route)
                onTabChange(3)
                ReactTooltip.hide()
              }}
            >
              View disabled pools
            </Button>
          </OverviewItem>
        )}
        {unusedPools.length > 0 && (
          <OverviewItem
            inline
            icon={{
              name: 'AwardsFlag1',
              color: theme.colors.orange500,
              size: 'large',
              variant: 'solid'
            }}
            text={`There ${unusedPools.length === 1 ? ' is' : ' are'} ${
              unusedPools.length
            } unbound license pool${
              unusedPools.length === 1 ? '' : 's'
            } , the license pool${
              unusedPools.length === 1 ? '' : 's'
            } must be bound to a fulfillment condition`}
          >
            <Button
              type='secondary'
              size='small'
              icon={{ name: 'ActionsFilter', variant: 'solid' }}
              onClick={() => {
                const route = `${ROUTE_DASHBOARD}?tab=3&filters=isUnbound%3DTrue`
                history.replace(route)
                onTabChange(3)
                ReactTooltip.hide()
              }}
            >
              View unbound pools
            </Button>
          </OverviewItem>
        )}

        {/** Fulfillment condition issues
         *   disabled = disabled, will not serve
         */}
        {conditionsDisabled.length > 0 && (
          <SectionHeader>
            <Text textStyle='h3' color={theme.colors.textbox.placeholder}>
              ABOUT FULFILLMENT CONDITIONS
            </Text>
          </SectionHeader>
        )}
        {conditionsDisabled.length > 0 && (
          <OverviewItem
            inline
            icon={{
              name: 'ConnectionServer',
              color: theme.colors.orange700,
              size: 'large'
            }}
            text={`There ${conditionsDisabled.length === 1 ? ' is' : ' are'} ${
              conditionsDisabled.length
            } disabled fulfillment condition${
              conditionsDisabled.length === 1 ? '' : 's'
            }, no features will be served from this fulfillment condition`}
          >
            <Button
              type='secondary'
              size='small'
              icon={{ name: 'ActionsFilter', variant: 'solid' }}
              onClick={() => {
                const route = `${ROUTE_DASHBOARD}?tab=4&filters=status%3DDISABLED`
                history.replace(route)
                onTabChange(4)
                //setFilter('disabled')
                ReactTooltip.hide()
              }}
            >
              View disabled fulfillment conditions
            </Button>
          </OverviewItem>
        )}
      </List>
      {actionOpen?.action === SERVER_ACTIONS.STATUS && (
        <LicenseServerActions
          server={server}
          detailed
          isOnLSDetailsPage
          onUpdate={clearAction}
          onClose={() => setActionOpen(undefined)}
          hideMenu
          statusOpen
        />
      )}
      {actionOpen?.action === SERVER_ACTIONS.BIND && (
        <LicenseServerActions
          server={server}
          detailed
          isOnLSDetailsPage
          onUpdate={clearAction}
          onClose={() => setActionOpen(undefined)}
          hideMenu
          bindOpen
        />
      )}
      {actionOpen?.action === SERVER_ACTIONS.EXPRESS && (
        <LicenseServerActions
          server={server}
          detailed
          isOnLSDetailsPage
          onUpdate={clearAction}
          onClose={() => setActionOpen(undefined)}
          hideMenu
          expressClsOpen
        />
      )}
      {actionOpen?.action === SERVER_ACTIONS.REGISTER_BIND && (
        <LicenseServerActions
          server={server}
          detailed
          isOnLSDetailsPage
          onUpdate={clearAction}
          onClose={() => setActionOpen(undefined)}
          hideMenu
          registerDlsOpen
        />
      )}
    </>
  )
}

const ItemStyle = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0;
  //background: gray;

  > div.inline {
    display: flex;
    align-items: center;
  }

  div.padd {
    margin-left: 1rem;
    align-self: flex-start;
  }
`
const ItemHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ inline }) => (inline ? '0' : '1')}rem;

  svg {
    margin-right: 1rem;
    flex-shrink: 0;
  }
`
const OverviewItem = ({
  icon: { name, color, size, variant },
  text,
  inline,
  children
}) => {
  const subStyle = inline ? { className: 'inline' } : {}
  const childStyle = inline ? { className: 'padd' } : {}

  return (
    <ItemStyle>
      <div {...subStyle}>
        <ItemHeader inline={inline}>
          <Icon
            name={name}
            color={color}
            size={size}
            variant={variant || 'regular'}
          />
          <Text>{text}</Text>
        </ItemHeader>
        <div {...childStyle}>{children}</div>
      </div>
    </ItemStyle>
  )
}
