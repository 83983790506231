import React, { useState, useMemo, useGlobal, useContext } from 'reactn'
import styled from 'styled-components'
import moment from 'moment'
import {
  PageHeader,
  FloatingDateRangePicker,
  FormikError,
  useFetch,
  Table
} from 'common'
import { CheckboxGroup, Checkbox, Text, Spinner } from '@kaizen-ui/complete'
import Select from '@kaizen-ui/select'
import {
  API_LICENSE_SERVER,
  API_METRICS,
  formatLicenseServerWithFeatures
} from '../../api'
import { SessionExpired } from '../../Components'
import {
  GLOBAL_ORG,
  GLOBAL_SERVER_ID,
  GLOBAL_SERVICE_INSTANCE_ID,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import { useEffect } from 'react'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { KaizenThemeContext } from '@kaizen-ui/styles'

const defaultRange = {
  start: moment(new Date())
    .add(-30, 'days')
    .startOf('day')
    .utc()
    .format(),
  end: moment(new Date())
    .endOf('day')
    .utc()
    .format()
}

const MetricOptions = [
  {
    label: 'Peak Concurrent Usage',
    value: 'peak_concurrent_usage',
    description:
      'Maximum number of leases active at any time in given duration for given feature'
  },
  {
    label: 'Total Leases Granted',
    value: 'total_leases_granted',
    description:
      'Total leases (active and released) granted in given duration for given feature'
  },
  {
    label: 'Total Lease Time',
    value: 'total_lease_time',
    description:
      'Sum of lease time across all leases (active and released) in given duration for given feature'
  },
  {
    label: 'Maximum Lease Time',
    value: 'maximum_lease_time',
    description:
      'Maximum lease time across all leases (active and released) granted in given duration for given feature'
  },
  {
    label: 'Minimum Lease Time',
    value: 'minimum_lease_time',
    description:
      'Minimum lease time across all leases (active and released) granted in given duration for given feature'
  },
  {
    label: 'Mean Lease Time',
    value: 'mean_lease_time',
    description:
      'Average of lease time for all leases (active and released) in given duration for given feature'
  }
]

const CONST_METRIC_TYPE = {
  DURATION: 'duration',
  INTEGER: 'integer'
}

export const Metrics = () => {
  const theme = useContext(KaizenThemeContext)
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [server, setServer] = useState(null)
  const [filter, setFilter] = useState({
    dates: {
      start: defaultRange.start,
      end: defaultRange.end
    }
  })
  const [selectedMetric, setSelectedMetric] = useState(null)
  const [serverFeatures, setServerFeatures] = useState([])
  const [excludePriorLeases, setExcludePriorLeases] = useState(false)
  const [metricResp, setMetricResp] = useState(false)

  /*  console.log('excludePriorLeases : ', excludePriorLeases)
  console.log('selectedFeatures : ', JSON.stringify(selectedFeatures))
  console.log('selectedMetric.value : ', selectedMetric?.value)*/

  useEffect(() => {
    if (server) {
      const unique = [
        ...new Set(
          server?.licenseServerFeatures?.map(feat => feat?.featureName)
        )
      ]
      setServerFeatures(
        unique.map(feat => {
          return {
            value: feat || '',
            checked: true
          }
        }) || []
      )
    }
  }, [server])

  const selectedFeatures = useMemo(() => {
    return (
      [
        ...new Set(serverFeatures?.filter(f => !!f.checked)?.map(f => f.value))
      ] || []
    )
  }, [serverFeatures])

  const { getData, abort, loading, error } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, serverId),
    actionLabel: 'Get license server details',
    SessionExpired: SessionExpired,
    normalizer: formatLicenseServerWithFeatures,
    supressToast: true
  })

  const {
    getData: getMetric,
    loading: gettingMetric,
    error: getMetricErr
  } = useFetch({
    endpoint: API_METRICS,
    method: 'POST',
    actionLabel: 'Get metric',
    SessionExpired: SessionExpired
  })

  useEffect(() => {
    const getServer = async () => {
      const data = await getData()
      if (data) {
        setServer(data)
      }
    }
    getServer()
    return () => {
      abort()
    }
  }, [vgId]) //eslint-disable-line react-hooks/exhaustive-deps

  const selectedDateRange = useMemo(() => {
    const { start, end } = filter.dates
    const mStart = moment(start)
    const mEnd = moment(end)
    /*   console.log(mEnd.diff(mStart, 'days'))
    console.log('formatDateTime(start) : ', formatDateTime(start))
    console.log('formatDateTime(end) : ', formatDateTime(end))*/
    const isInvalid = mEnd.diff(mStart, 'days') > 91 //equivalent to 92 days, eg 1st Jan to 31st March allowed
    return { start: new Date(start), end: new Date(end), isInvalid }
  }, [filter])

  /*  moment(start).format('YYYY-MM-DDTHH:mm:ss')*/
  const getUtilisationReport = async () => {
    const { start, end } = filter.dates
    const headers = {
      'x-nv-service-instance-id': serviceInstanceId
    }
    const startVal = start
      ? start
      : moment(new Date())
          .add(-30, 'days')
          .startOf('day')
          .utc()
          .format()
    const endVal = end
      ? end
      : moment(new Date())
          .endOf('day')
          .utc()
          .format()
    const body = {
      start_time: startVal,
      end_time: endVal,
      metric: selectedMetric.value,
      features: selectedFeatures,
      ...(excludePriorLeases ? { exclude_prior_leases: true } : {})
    }
    const data = await getMetric({
      body,
      headers
    })

    if (data) {
      setMetricResp(data)
    } else {
      /* setEvents([])
      setPageCount(0)
      setTotalResults(0)*/
    }
  }
  /* const metricResp = {
    metric_name: 'peak_concurrent_usage',
    property_type: 'integer or duration',
    data: [
      {
        feature_name: 'Quadro-Virtual-DWS',
        metric_value: 3679,
        lease_ref: ['sassdasd', 'asdadsasdd'],
        lease_active: 20
      },
      {
        feature_name: 'vApps',
        metric_value: 2000,
        lease_ref: ['asdasdsada'],
        lease_active: 420
      }
    ]
  }
*/

  const property_type = useMemo(() => {
    return metricResp?.property_type || ''
  }, [metricResp])

  const columns = useMemo(
    () => [
      {
        Header: 'Feature Name',
        accessor: 'feature_name',
        disableFilters: true
      },
      {
        Header: 'Metric Value',
        accessor: 'metric_value',
        disableFilters: true,
        Cell: ({ row }) => {
          const { metric_value } = row.original
          /* const metric_value = 'P3Y6M4DT12H30M5S'*/
          const durationValue =
            property_type === CONST_METRIC_TYPE.DURATION
              ? typeof metric_value === 'string'
                ? metric_value.toUpperCase()
                : ''
              : ''

          return property_type === CONST_METRIC_TYPE.DURATION &&
            durationValue ? (
            <span>
              {`
                ${moment.duration(durationValue).years()}Y, 
                ${moment.duration(durationValue).months()}M, 
                ${moment.duration(durationValue).weeks()}W,
                ${moment.duration(durationValue).days()}D, 
                ${moment.duration(durationValue).hours()}H,
                ${moment.duration(durationValue).minutes()}M
                ${moment.duration(durationValue).seconds()}S
              `}
            </span>
          ) : (
            metric_value
          )
        }
      },
      {
        Header: 'Lease Reference(s)',
        accessor: 'lease_ref',
        disableFilters: true,
        Cell: ({ row }) => {
          const { lease_ref } = row.original
          return lease_ref ? lease_ref.join(',') : '-'
        }
      },
      {
        Header: 'Active Leases',
        accessor: 'leases_active',
        disableFilters: true,
        Cell: ({ row }) => {
          const { leases_active } = row.original
          return leases_active || '-'
        }
      }
    ],
    [property_type]
  )

  return (
    <>
      <PageHeader
        title='Metrics'
        subTitle='View licensing metrics for this license server'
      ></PageHeader>
      <div>
        <StyledField>
          <Text textStyle='label'>Date range (upto 92 days)</Text>
          <div style={{ display: 'flex' }}>
            <FloatingDateRangePicker
              tip='Select the date range of metrics to be exported'
              value={selectedDateRange}
              onChange={val => {
                const { dates, ...rest } = filter
                if (val.start && val.end) {
                  setFilter({
                    dates: {
                      start: moment(val.start)
                        .utc()
                        .format(),
                      end: moment(val.end)
                        .utc()
                        .format()
                    },
                    ...rest
                  })
                } else {
                  setFilter({ ...rest })
                }
              }}
              defaultValue={defaultRange}
              maxEndDate={new Date()}
              // maxStartDate={
              //   new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
              // }
            />
          </div>
          <FormikError>
            {selectedDateRange?.isInvalid ? 'Must be within 92 day range' : ''}
          </FormikError>
        </StyledField>
        <StyledField>
          <Text textStyle='label'>Metric: </Text>
          <Select
            value={selectedMetric}
            options={MetricOptions}
            clearable={true}
            onChange={selection => {
              /*console.log('selection : ', JSON.stringify(selection))*/
              setSelectedMetric(selection || null)
            }}
          />
          {selectedMetric && (
            <StyledMetricDesc>
              <Icon name='StatusCircleInformation' /> &nbsp;{' '}
              <Text textStyle='p2' color={theme.colors.textbox.placeholder}>
                {MetricOptions.find(met => selectedMetric.value === met.value)
                  ?.description || ''}
              </Text>
            </StyledMetricDesc>
          )}
        </StyledField>
        <Text textStyle='label'>Features: </Text>
        <StyledField>
          {loading ? (
            <Spinner />
          ) : error ? (
            <FormikError>{error}</FormikError>
          ) : (
            <CheckboxGroup
              name='features-checkbox-group'
              onChange={e => {
                /* console.log(e.target.checked)
                console.log(e.target.id)*/
                let serverFeatCopy = JSON.parse(JSON.stringify(serverFeatures))
                serverFeatCopy.forEach(feat => {
                  if (feat.value === e.target.id) {
                    feat.checked = e.target.checked
                  }
                })
                setServerFeatures(serverFeatCopy)
              }}
            >
              {serverFeatures &&
                serverFeatures.length &&
                serverFeatures?.map(feat => (
                  <span key={feat.value}>
                    <Checkbox
                      id={feat.value}
                      label={feat.value}
                      value={feat.value}
                      key={feat.value}
                      checked={true}
                    >
                      {feat.value}
                    </Checkbox>
                  </span>
                ))}
            </CheckboxGroup>
          )}
        </StyledField>

        {selectedMetric?.value === 'peak_concurrent_usage' && (
          <>
            <Text textStyle='label'>
              Exclude leases active prior to the start of the interval:{' '}
            </Text>
            <StyledField>
              <Checkbox
                id={'Exclude prior leases'}
                label={'Exclude prior leases'}
                key={'Exclude prior leases'}
                onChange={e => {
                  setExcludePriorLeases(e.target.checked)
                }}
              />
            </StyledField>
          </>
        )}

        <Button
          onClick={() => {
            getUtilisationReport()
          }}
          disabled={
            !selectedMetric ||
            selectedDateRange?.isInvalid ||
            !selectedFeatures?.length ||
            gettingMetric
          }
        >
          Get License Utilization
        </Button>
      </div>
      <br />
      <div>
        {/* {!metricResp?.data?.length && gettingMetric && <Spinner />}*/}
        <Table
          columns={columns}
          data={metricResp?.data || []}
          dataId={'feature_name'}
          loading={gettingMetric}
          fetching={gettingMetric}
          error={getMetricErr}
          label={'Metrics'}
          initialPageSize={5}
          disablePageSizeSelection
          /*lastUpdate={lastUpdate}*/
          /* refresh={() => setRefresh(val => !val)}*/
        />
      </div>
    </>
  )
}

const StyledField = styled.div`
  margin-bottom: 1rem;

  .modal-export-events {
    background-color: ${({ theme }) => theme.colors.pageBackground};
    .modal-subtitle {
      margin-bottom: 0 !important;
    }
  }
`
const StyledMetricDesc = styled.div`
  padding-top: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: flex-start;
`

export const StyledNoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
