import React, { useState } from 'reactn'
import styled from 'styled-components'
import Modal from '@kaizen-ui/modal'
import Button from '@kaizen-ui/button'
import Result from '@kaizen-ui/result'
import {
  InlineButton,
  ModalButtons,
  useAppNotifications,
  useFetch
} from 'common'
import { API_NLL_LEASES_RELEASE } from '../../api'

const StyledResult = styled.div`
  text-align: center;
  > div {
    border: none;
  }
`

export const LeaseActionsNLL = ({ lease, server, onUpdate }) => {
  const { serviceInstanceId, scopeReference } = server || {}
  const { id } = lease || {}
  const [open, setOpen] = useState(false)

  return (
    <>
      <div
        style={{ marginRight: '0.5rem' }}
        data-tip={'Force release all leases from this file'}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <InlineButton
          type='critical'
          variant='link'
          onClick={() => setOpen(true)}
        >
          Release
        </InlineButton>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Force Release'
          subtitle="Forcefully release 'stuck' leases without awaiting expiration"
          open={open}
          onClose={() => setOpen(false)}
        >
          {open && (
            <ForceRelease
              leaseId={id}
              onUpdate={() => {
                onUpdate()
                setOpen(false)
              }}
              serviceInstanceId={serviceInstanceId}
              scopeReference={scopeReference}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export const ForceRelease = ({
  leaseId,
  onUpdate,
  serviceInstanceId,
  scopeReference
}) => {
  const { getData: sendRequest, loading: submitting } = useFetch({
    endpoint: API_NLL_LEASES_RELEASE,
    actionLabel: 'Force release leases from file',
    method: 'PATCH',
    responseOnly: true
  })
  const { notify } = useAppNotifications()

  const submit = async () => {
    const headers = { 'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId }
    const body = {
      nll_release_request: {
        scope_ref: scopeReference,
        transaction_id: leaseId
      }
    }
    const result = await sendRequest({ headers, body })

    if (result) {
      const msg = `Released all leases in lease file ${leaseId} successfully!`
      notify(msg, null, null, 'Force release all leases in the file')
      onUpdate()
    }
  }

  return (
    <>
      <StyledResult>
        <Result
          status={submitting ? 'loading' : 'warning'}
          title={
            submitting
              ? 'Releasing...'
              : 'Are you sure you want to force release?'
          }
          subTitle={leaseId}
        />
      </StyledResult>
      <ModalButtons>
        <Button type='critical' onClick={submit} disabled={submitting}>
          Force Release
        </Button>
      </ModalButtons>
    </>
  )
}
