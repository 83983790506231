import React, {
  useState,
  useGlobal,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'reactn'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Tabs, { Tab } from '@kaizen-ui/tabs'
import Block from '@kaizen-ui/block'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import queryString from 'query-string'
import {
  GLOBAL_ORG,
  GLOBAL_SERVER_ID,
  GLOBAL_VA_VERSION,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import {
  useFetch,
  PageHeader,
  FloatingSpinner,
  Table,
  formatDateTime
} from 'common'
import {
  LeaseActions,
  LicenseServerActions,
  ROUTE_DASHBOARD,
  SessionExpired
} from '../../Components'
import {
  API_LEASES,
  API_LICENSE_SERVER,
  API_LOCKED_LEASES,
  DOCS_ROOT,
  formatLeasesFlat,
  formatLicenseServerWithFeatures,
  formatNllTransactions,
  LS_STATUS_ENABLED,
  LS_TYPE_FLEXERA,
  LS_TYPE_NVIDIA
} from '../../api'
import ReactTooltip from 'react-tooltip'
import { NvidiaProperties } from './NvidiaProperties'
import { FlexeraProperties } from './FlexeraProperties'
import { ServerFeatures } from './ServerFeatures'
import { LicensePools } from './LicensePools'
import { FullfillmentConditions } from './FulfillmentConditions'
import { LicenseServerOverview } from './LicenseServerOverview'
import { Icon, Text } from '@kaizen-ui/complete'
import { LeaseActionsNLL } from '../../Components'

const StyledTabs = styled.div`
  //   overflow: hidden;
  //   display: flex;
  //   flex-direction: column;
  //   min-height: 0;
  margin: 0.5rem;

  .content {
    //display: flex;
    //flex-direction: column;
    //min-height: 0;
    //overflow: hidden;
    //flex: 1;
    padding: 1rem 0 0 0;

    > div {
      //   overflow: hidden;
      //   display: flex;
      //   flex-direction: column;
      //   min-height: 0;
      width: 100%;
    }
  }
`
const StyledBlock = styled.div`
  > div[elevation] {
    overflow: auto;

    > div[title] {
      :hover {
        > span {
          color: ${({ theme }) =>
            theme.darkMode
              ? theme.colors.lightGray000
              : theme.colors.lightGray500};
        }
      }
    }

    button {
      z-index: 0;
    }
  }
`
const StyledHeader = styled.span`
  display: flex;
  > span {
    margin-left: 0.5rem;
  }
`
export const LicenseServerDetails = ({ serviceInstance }) => {
  const history = useHistory()
  const [serverId] = useGlobal(GLOBAL_SERVER_ID)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const [refresh, setRefresh] = useState(false)
  const [refreshLeases, setRefreshLeases] = useState(false)
  const [refreshNllLeases, setRefreshNllLeases] = useState(false)
  const theme = useContext(KaizenThemeContext)
  const [org] = useGlobal(GLOBAL_ORG)
  const [virtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const orgId = org && org.id
  const vgId = virtualGroup && virtualGroup.id
  const [propsCollapsed, setPropsCollapsed] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [server, setServer] = useState({})
  const [leases, setLeases] = useState([])
  const [nllLeases, setNllLeases] = useState([])
  const { getData, abort, loading, lastUpdate } = useFetch({
    endpoint: API_LICENSE_SERVER(orgId, vgId, serverId),
    actionLabel: 'Get license server details',
    SessionExpired: SessionExpired,
    normalizer: formatLicenseServerWithFeatures
  })
  //const { tab, search } = useParams()
  const { tab, search } = queryString.parse(window.location.search)
  const tabMax = 5
  const tabMin = 1
  const tabNumber = Number(tab)
  const [selectedTab, setSelectedTab] = useState(
    tabNumber <= tabMax && tabNumber >= tabMin ? tabNumber : tabMin
  )
  //const [filter, setFilter] = useState(search)
  const { type, name, status, serviceInstanceId, isSimpleMode } = server

  const isFlex = type === LS_TYPE_FLEXERA
  const isNvidia = type === LS_TYPE_NVIDIA
  const isEnabled = status === LS_STATUS_ENABLED

  const {
    getData: getLeaseData,
    abort: abortLeases,
    error: leaseError,
    loading: loadingLeases,
    lastUpdate: lastUpdateLeases
  } = useFetch({
    endpoint: API_LEASES(orgId, vgId),
    actionLabel: `Get leases for server ${name}`,
    SessionExpired: SessionExpired,
    normalizer: formatLeasesFlat
  })
  const {
    getData: getNllLeaseData,
    abort: abortNllLeases,
    error: leaseNllError,
    loading: loadingNllLeases,
    lastUpdate: lastUpdateNllLeases
  } = useFetch({
    endpoint: API_LOCKED_LEASES(orgId, vgId, serverId),
    actionLabel: `Get disconnected leases for server ${name}`,
    SessionExpired: SessionExpired,
    normalizer: formatNllTransactions
  })

  const leaseColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'leaseId'
      },
      {
        Header: 'Feature Name',
        accessor: 'featureName'
      },
      { Header: 'VGPU ID', accessor: 'gpu_id', hideOnLoad: true },
      {
        Header: () => (
          <StyledHeader>
            Client Origin Ref{' '}
            <span data-tip='Unique token to identify a licensed client'>
              <Icon name='StatusCircleInformation' />
            </span>
          </StyledHeader>
        ),
        label: 'Client Origin Ref',
        accessor: 'originRef'
      },
      {
        Header: 'Client Hostname',
        accessor: 'hostname',
        Cell: ({ row }) => {
          const { hostname } = row.original
          return hostname ? hostname : 'unavailable'
        }
      },
      {
        Header: 'Client MAC Addresses',
        accessor: 'macAddresses',
        Cell: ({ row }) => {
          const { macAddresses } = row.original

          return macAddresses && macAddresses.length
            ? macAddresses.join(', ')
            : 'unavailable'
        }
      },
      {
        Header: 'Client IP Addresses',
        accessor: 'ipAddresses',
        Cell: ({ row }) => {
          const { ipAddresses } = row.original

          return ipAddresses && ipAddresses.length
            ? ipAddresses.join(', ')
            : 'unavailable'
        }
      }
    ],
    []
  )

  const nllLeaseColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Created',
        accessor: 'created_at_local'
      }
    ],
    []
  )

  useEffect(() => {
    const getServer = async () => {
      const data = await getData()

      if (data) {
        setServer(data)
        setPageLoading(false)
      } else {
        history.goBack()
      }
    }
    getServer()

    return () => {
      abort()
    }
  }, [vgId, refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if (!server.id) return
    //if (server.deployedOn !== SERVER_DEPLOY_STATUS.CLOUD) return

    if (!serviceInstanceId || !serverId || isSimpleMode) return

    const instanceHeader = serviceInstanceId
      ? {
          'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
        }
      : {}
    const getLeases = async () => {
      const data = await getLeaseData({
        headers: instanceHeader,
        parameters: { license_server_ids: [serverId] }
      })

      if (data) {
        setLeases(data)
      } else {
        setLeases([])
      }
    }
    getLeases()

    return () => {
      abortLeases()
    }
  }, [serverId, refresh, refreshLeases, isSimpleMode, serviceInstanceId]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!serviceInstanceId || !serverId || !isSimpleMode) return

    const instanceHeader = serviceInstanceId
      ? {
          'X-NV-SERVICE-INSTANCE-ID': serviceInstanceId
        }
      : {}
    const getNllLeases = async () => {
      const data = await getNllLeaseData({
        headers: instanceHeader,
        parameters: { license_server_ids: [serverId] }
      })
      if (data) {
        setNllLeases(data)
      } else {
        setNllLeases([])
      }
    }
    getNllLeases()

    return () => {
      abortNllLeases()
    }
  }, [serverId, refreshNllLeases, refresh, isSimpleMode, serviceInstanceId]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  React.useEffect(() => {
    document.title = 'DLS - Server Details'
  }, [])

  useEffect(() => {
    setSelectedTab(
      tabNumber <= tabMax && tabNumber >= tabMin ? tabNumber : tabMin
    )
  }, [history.location]) //eslint-disable-line react-hooks/exhaustive-deps

  const rowActions = useCallback((row, related) => {
    const { serviceInstanceId } = related

    return (
      <LeaseActions
        lease={row.original}
        onUpdate={() => setRefresh(val => !val)}
        serviceInstanceId={serviceInstanceId}
      />
    )
  }, [])

  const rowActionsNLL = useCallback(
    row => {
      return (
        <LeaseActionsNLL
          lease={row.original}
          onUpdate={() => setRefresh(val => !val)}
          server={server}
        />
      )
    },
    [server]
  )

  const nllFileView = useCallback(
    ({ row }) => {
      const files = row.original.nll_lic_files

      return files.map(file => {
        return (
          <div key={file.id} style={{ margin: '0.5rem' }}>
            <Block
            //titleIcon={{ name: 'FileBase', color: '#76b900' }}
            //title={file.name}
            //collapsible
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name='FileBase' color='#76b900' size='large' />
                <span style={{ marginLeft: '0.5rem' }}>{file.name}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  color: theme.colors.textbox.placeholder
                }}
              >
                <div>
                  {file.platform_evidence?.map((pe, index) => {
                    const { id, val } = pe
                    return (
                      <div key={index} style={{ margin: '0.25rem' }}>
                        <Text textStyle='label'>{id}: </Text>
                        <Text textStyle='code'>{val}</Text>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                {file.leases?.map(l => {
                  const {
                    lease_ref,
                    product_name,
                    feature_version,
                    lease_expire
                  } = l
                  return (
                    <div key={lease_ref} style={{ margin: '0.25rem' }}>
                      {product_name}-{feature_version} {'-'} lease expires:{' '}
                      {formatDateTime(lease_expire)}
                    </div>
                  )
                })}
              </div>
            </Block>
          </div>
        )
      })
    },
    [theme]
  )

  if (pageLoading) return <FloatingSpinner visible />

  return (
    <>
      <FloatingSpinner visible={loading} />
      <PageHeader
        title='License Server Details'
        helpUrl={DOCS_ROOT(version)}
        tip='Get help with license servers'
        subTitle={'View details and manage the installed license server'}
      >
        <ButtonGroup>
          <Button
            type='secondary'
            onClick={() => setRefresh(val => !val)}
            icon={{ name: 'CloudRefresh' }}
          >
            Refresh
          </Button>
          <LicenseServerActions
            detailed
            server={server}
            serviceInstance={serviceInstance}
            onUpdate={() => setRefresh(val => !val)}
            isOnLSDetailsPage
          />
        </ButtonGroup>
      </PageHeader>
      <StyledBlock theme={theme}>
        <Block
          collapsible
          collapsed={propsCollapsed}
          onToggle={() => setPropsCollapsed(val => !val)}
          title={isNvidia ? `${name} is ${status}` : name}
          titleIcon={{
            name: 'ConnectionServer',
            color: isEnabled ? theme.colors.brand : theme.colors.red500,
            size: 36
          }}
        >
          <>
            {isNvidia && <NvidiaProperties server={server} />}
            {isFlex && <FlexeraProperties server={server} />}
          </>
        </Block>
      </StyledBlock>

      {/* {isNvidia && !isEnabled && (
        <div style={{ margin: '1rem -1rem' }}>
          <Banner status='critical' elevation='lowest'>
            {name} is currently in edit mode. No feature licenses will be
            served, but you can make changes to the allocated features, pools,
            conditions
          </Banner>
        </div>
      )} */}
      {isNvidia && (
        <StyledTabs>
          <Tabs
            onTabChange={val => {
              setSelectedTab(val)
              history.replace(`${ROUTE_DASHBOARD}?tab=${val}`)
            }}
            selectedTabId={selectedTab}
          >
            <Tab id={1} title='Overview'>
              <LicenseServerOverview
                server={server}
                onTabChange={setSelectedTab}
                onUpdate={() => setRefresh(val => !val)}
              />
            </Tab>
            <Tab id={2} title='Server Features'>
              <div>
                <ServerFeatures
                  server={server}
                  //onFilter={setFilter}
                  search={search}
                  refresh={() => setRefresh(val => !val)}
                  lastUpdate={lastUpdate}
                />
              </div>
            </Tab>
            <Tab id={3} title='License Pools'>
              <div>
                <LicensePools
                  server={server}
                  //onFilter={setFilter}
                  search={search}
                  refresh={() => setRefresh(val => !val)}
                  lastUpdate={lastUpdate}
                />
              </div>
            </Tab>
            <Tab id={4} title='Fulfillment Conditions'>
              <FullfillmentConditions
                server={server}
                //onFilter={setFilter}
                search={search}
                refresh={() => setRefresh(val => !val)}
                lastUpdate={lastUpdate}
                onTabChange={setSelectedTab}
              />
            </Tab>
            <Tab id={5} title='Leases'>
              {isSimpleMode ? (
                <Table
                  columns={nllLeaseColumns}
                  data={nllLeases}
                  fetching={loadingNllLeases}
                  loading={pageLoading}
                  error={leaseNllError}
                  label={'lease files'}
                  refresh={() => setRefreshNllLeases(val => !val)}
                  lastUpdate={lastUpdateNllLeases}
                  renderRowSubComponent={nllFileView}
                  relatedData={{ serviceInstanceId }}
                  globalSearch={search}
                  rowActions={rowActionsNLL}
                />
              ) : (
                <Table
                  dataId='leaseId'
                  columns={leaseColumns}
                  data={leases}
                  fetching={loadingLeases}
                  loading={pageLoading}
                  error={leaseError}
                  label={'leases'}
                  refresh={() => setRefreshLeases(val => !val)}
                  lastUpdate={lastUpdateLeases}
                  rowActions={rowActions}
                  //onFilter={setFilter}
                  relatedData={{ serviceInstanceId }}
                  globalSearch={search}
                />
              )}
            </Tab>
          </Tabs>
        </StyledTabs>
      )}
    </>
  )
}
