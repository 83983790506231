import React, {
  useGlobal,
  useContext,
  useState,
  useMemo,
  useEffect
} from 'reactn'
import styled from 'styled-components'
import Tag from '@kaizen-ui/tag'
import Text from '@kaizen-ui/text'
import Switch from '@kaizen-ui/switch'
import ReactTooltip from 'react-tooltip'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import {
  GLOBAL_OFFLINE_UPGRADE_INPROGRESS,
  GLOBAL_UPGRADE_STATUS,
  GLOBAL_VA_VERSION
} from '../../globalState'

import { OnnlineUpgrade } from './OnlineUpgrade'
import { OfflineUpgrade } from './OfflineUpgrade'
import { MIGRATION_STATUS } from '../../api'

export const BlockSection = styled.div`
  margin: 0;
`
export const Step = styled.span`
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.textbox.placeholder};
  font-size: 1.2rem;
`
export const BlockTitle = styled.h3`
  display: flex;
  align-items: center;
`
export const BlockContents = styled.div`
  font-size: 0.75rem;

  ${BlockSection}+${BlockSection} {
    margin-top: 2rem;
  }

  .kaizen-button.link {
    padding: 0;
  }
`

export const Upgrade = () => {
  const theme = useContext(KaizenThemeContext)
  const [version] = useGlobal(GLOBAL_VA_VERSION)
  const [offlineUpgrade] = useGlobal(GLOBAL_OFFLINE_UPGRADE_INPROGRESS)
  const [migrationStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const isOnlineUpgradeInProgress = useMemo(
    () =>
      [
        MIGRATION_STATUS.VALIDATED,
        MIGRATION_STATUS.DATA_TRANSFER_INITIATED,
        MIGRATION_STATUS.DATA_TRANSFER_COMPLETED
      ].includes(migrationStatus?.state),
    [migrationStatus]
  )
  const [offline, setOffline] = useState(offlineUpgrade)

  useEffect(() => {
    ReactTooltip.rebuild()
  })
  return (
    <Text>
      <h4>
        <span style={{ marginRight: '0.5rem' }}>
          NVIDIA Delegated License System:
        </span>
        <Tag
          clickable={false}
          color='green'
          variant={theme.darkMode ? 'outline' : 'solid'}
        >
          {version}
        </Tag>
      </h4>
      <div style={{ marginLeft: '-1rem', marginTop: '-1rem' }}>
        <span
          data-tip={
            offlineUpgrade
              ? 'Cannot switch to online upgrade if offline upgrade is in progress.'
              : isOnlineUpgradeInProgress
              ? 'Cannot switch to offline upgrade if online upgrade is in progress.'
              : null
          }
        >
          <Switch
            id={'upgradeToggle'}
            checked={offline}
            onChange={() => setOffline(val => !val)}
            label='Offline upgrade'
            disabled={offlineUpgrade || isOnlineUpgradeInProgress}
          />
        </span>
      </div>
      {offline ? <OfflineUpgrade /> : <OnnlineUpgrade />}
    </Text>
  )
}
