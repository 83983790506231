import React, { useState, useGlobal, useMemo, useEffect } from 'reactn'
import { useFormik } from 'formik'
import Button from '@kaizen-ui/button'
import Textbox from '@kaizen-ui/textbox'
import Modal from '@kaizen-ui/modal'
import Result from '@kaizen-ui/result'
import * as Yup from 'yup'
import {
  API_MIGRATION_TARGET_PING,
  API_SERVICE_INSTANCE_MIGRATION_START,
  IpHostnameRegex,
  MIGRATION_STATUS
} from '../../api'
import {
  GLOBAL_MAIN_CONTENT_REF,
  GLOBAL_UPGRADE_REFRESH,
  GLOBAL_UPGRADE_STATUS
} from '../../globalState'
import {
  ModalButtons,
  ModalSpinner,
  useAppNotifications,
  useFetch
} from 'common'

const initialValues = {
  ipAddress: ''
}

const currentHost = window.location.hostname

const validationSchema = Yup.object({
  ipAddress: Yup.string()
    .notOneOf(
      [currentHost],
      "Value can't be same as current node's IP address or FQDN"
    )
    .matches(IpHostnameRegex, 'Must be a valid IP address or FQDN')
    .required('IP address or FQDN of new virtual appliance is required')
})
export const MigrateData = () => {
  const [migrationStatus] = useGlobal(GLOBAL_UPGRADE_STATUS)
  const preventMigration = useMemo(
    () =>
      [
        MIGRATION_STATUS.DATA_TRANSFER_INITIATED,
        MIGRATION_STATUS.VALIDATED
      ].includes(migrationStatus?.state),
    [migrationStatus]
  )
  const showMigrateForm = useMemo(
    () =>
      !migrationStatus?.state ||
      [MIGRATION_STATUS.FAILED, MIGRATION_STATUS.COMPLETED].includes(
        migrationStatus?.state
      ),
    [migrationStatus]
  )
  const showRemigrate = useMemo(
    () =>
      [MIGRATION_STATUS.DATA_TRANSFER_COMPLETED].includes(
        migrationStatus?.state
      ) && !migrationStatus?.isTargetNode,
    [migrationStatus]
  )

  const { notify } = useAppNotifications()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [pingSuccess, setPingSuccess] = useState(false)
  const [, setRefresh] = useGlobal(GLOBAL_UPGRADE_REFRESH)
  const {
    getData: sendPing,
    loading: pinging,
    error: pingError,
    resetError: resetPingError
  } = useFetch({
    endpoint: API_MIGRATION_TARGET_PING,
    actionLabel: 'Pinging node for online data migration',
    method: 'POST',
    supressToast: true
  })
  const { getData: sendRequest, loading, error, resetError } = useFetch({
    endpoint: API_SERVICE_INSTANCE_MIGRATION_START,
    actionLabel: 'Starting data migration',
    method: 'POST'
  })
  const [scrollRef] = useGlobal(GLOBAL_MAIN_CONTENT_REF)

  const onSubmit = async values => {
    const { ipAddress } = values
    setPingSuccess(false)
    const body = {
      ipAddress
    }
    const result = await sendPing({ body })
    if (result) {
      setPingSuccess(true)
    }
  }

  const triggerMigration = async () => {
    scrollRef?.current && scrollRef.current.scrollTo(0, 0)
    const body =
      migrationStatus?.state === MIGRATION_STATUS.DATA_TRANSFER_COMPLETED
        ? { ipAddress: migrationStatus?.targetIP, retry: true }
        : formik.values
    const data = await sendRequest({ body })

    if (data) {
      notify(
        'Migration triggered successfully',
        null,
        null,
        'Data migration started successfully'
      )
      setConfirmOpen(false)
    }
    setRefresh(val => !val)
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: onSubmit
  })

  useEffect(() => {
    if (pingSuccess) {
      setPingSuccess(false)
    }
    if (error) {
      resetError()
    }
    if (pingError) {
      resetPingError()
    }
  }, [formik?.values?.ipAddress]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {showRemigrate ? (
        <>
          <Button
            onClick={triggerMigration}
            disabled={preventMigration || loading}
          >
            Migrate again
          </Button>
        </>
      ) : showMigrateForm ? (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '1rem', minWidth: '440px' }}>
              <Textbox
                value={formik.values.ipAddress}
                id='ipAddress'
                name='ipAddress'
                placeholder='Enter the IP address or FQDN of the target DLS virtual appliance'
                className='nvl'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                validationMessage={
                  formik.touched.ipAddress && formik.errors.ipAddress
                }
              />
            </div>

            <Button
              variant='link'
              type={'primary'}
              icon={{ name: 'ObjectsNetwork' }}
              onClick={formik.handleSubmit}
              disabled={preventMigration || pinging}
            >
              Ping
            </Button>
          </div>
          <br />
          <div style={{ width: '520px' }}>
            <Result
              status={
                loading
                  ? 'loading'
                  : !!error
                  ? 'error'
                  : pinging
                  ? 'loading'
                  : pingSuccess
                  ? 'success'
                  : pingError
                  ? 'error'
                  : 'info'
              }
              title={
                loading
                  ? 'Migrating Data'
                  : !!error
                  ? undefined
                  : pinging
                  ? 'Pinging node'
                  : pingSuccess
                  ? 'Success!'
                  : undefined
              }
              subTitle={
                loading
                  ? 'Migrating Data'
                  : !!error
                  ? error
                  : pinging
                  ? 'Checking node status'
                  : pingSuccess
                  ? "Click 'Migrate Data' to start data migration"
                  : pingError
                  ? pingError
                  : 'Awaiting target node information'
              }
              actions={
                pingSuccess
                  ? [
                      {
                        children: 'Migrate data',
                        onClick: () => setConfirmOpen(true),
                        disabled: preventMigration || loading,
                        key: 0
                      }
                    ]
                  : undefined
              }
            />
          </div>
        </div>
      ) : null}
      <Modal
        title='Proceed with migration?'
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        {confirmOpen && (
          <>
            {loading && <ModalSpinner />}
            <p>
              This will migrate the license configuration of current virtual
              appliance to the new virtual appliance. Events on the current
              virtual appliance will not be migrated. If the license server is
              enabled for leasing operations, it will be disabled. Do you want
              to continue?
            </p>

            <ModalButtons>
              <Button
                onClick={triggerMigration}
                disabled={preventMigration || loading}
              >
                Start Migration
              </Button>
            </ModalButtons>
          </>
        )}
      </Modal>
    </>
  )
}
