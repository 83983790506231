import React, { useContext } from 'react'
import styled from 'styled-components'
import Icon from '@kaizen-ui/icon'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { SERVER_DEPLOY_STATUS, LS_STATUS_ENABLED } from '../../api'
import { formatDateTime, Pill } from 'common'
import { Link } from 'react-router-dom'
import { ROUTE_SERVICE_INSTANCES } from '../../Components'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const NvidiaProperties = ({ server }) => {
  const theme = useContext(KaizenThemeContext)
  const {
    type,
    createdAt,
    description,
    lastUpdatedAt,
    status,
    serviceInstanceName,
    serviceInstanceType,
    serviceInstanceId,
    deployedOn,
    isSimpleMode,
    scopeReference
  } = server
  const isEnabled = status === LS_STATUS_ENABLED

  return (
    <div style={{ margin: '0.5rem -0.25rem' }}>
      <Row>
        <Pill
          label='Status'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={isEnabled ? 'ServerCheck' : 'ServerError'}
                color={isEnabled ? theme.colors.brand : theme.colors.red500}
              />
              <span style={{ marginLeft: '0.5rem' }}>{status}</span>
            </div>
          }
        />
        <Pill label='Type' value={type} />
        <Pill label='Created' value={formatDateTime(createdAt)} />
        <Pill label='Modified' value={formatDateTime(lastUpdatedAt)} />
      </Row>
      <Row>
        <Pill
          label='Service Instance'
          value={
            serviceInstanceName ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '0.5rem' }}>
                  <Link
                    to={`${ROUTE_SERVICE_INSTANCES}?filters=xid%3D${serviceInstanceId}`}
                  >
                    {serviceInstanceName}
                  </Link>
                </span>
                <Icon
                  name={
                    serviceInstanceType === 'Cloud'
                      ? 'CloudCheckmark'
                      : 'DatabaseBase'
                  }
                  color={theme.colors.brand}
                />
                <span style={{ marginLeft: '0.5rem' }}>
                  {serviceInstanceType === 'Cloud' ? 'CLS' : 'DLS'}
                </span>
              </div>
            ) : (
              <span style={{ color: theme.colors.grayLight }}>UNBOUND</span>
            )
          }
        />
        <Pill
          label='Install Status'
          value={
            serviceInstanceName ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  name={
                    serviceInstanceName ? 'ServerCheck' : 'ConnectionServer'
                  }
                  color={
                    deployedOn === SERVER_DEPLOY_STATUS.NOT_DEPLOYED
                      ? theme.colors.red500
                      : theme.colors.brand
                  }
                />
                <span style={{ marginLeft: '0.5rem' }}>
                  <span>INSTALLED</span>
                </span>
              </div>
            ) : (
              <span style={{ color: theme.colors.grayLight }}>UNBOUND</span>
            )
          }
        />
        <Pill
          label='Leasing Mode'
          value={isSimpleMode ? 'Disconnected' : 'Standard'}
        />
      </Row>
      <Row>
        <Pill label='Description' value={description} />
        {scopeReference ? (
          <Pill label='Scope Reference' value={scopeReference} />
        ) : null}
      </Row>
    </div>
  )
}
