import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Banner from '@kaizen-ui/banner'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import Progress from '@kaizen-ui/progress'
import { MIGRATION_STATUS, MIGRATION_STATUS_DISPLAY } from '../../api'
import { useAuth, useUpgradeNotifier } from '../../hooks'
import { AckUpgrade } from '..'
import { ROUTE_MAINTENANCE } from '../../Components'

const Outer = styled.div`
  margin-top: 1rem;

  .content {
    flex: 1;
  }
`
export const UpgradeNotifier = () => {
  const { migrationStatus, pendingMigration } = useUpgradeNotifier()
  const { user } = useAuth()
  const theme = useContext(KaizenThemeContext)

  return (
    <>
      {pendingMigration && user && (
        <Outer>
          <Banner
            status='passive'
            icon={{ name: 'CloudTransfer', size: 36 }}
            // title={
            //   migrationStatus?.state
            //     ? MIGRATION_STATUS_DISPLAY[migrationStatus?.state]
            //     : 'Migration in progress'
            // }
            title={
              migrationStatus?.state === MIGRATION_STATUS.FAILED
                ? 'Upgrade Failed'
                : 'Upgrade In Progress'
            }
          >
            {migrationStatus?.state ===
              MIGRATION_STATUS.DATA_TRANSFER_INITIATED &&
              migrationStatus?.state === MIGRATION_STATUS.VALIDATED && (
                <Progress indeterminate size='thin' />
              )}
            <div
              style={{
                margin: '0 0 1rem 0',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div>
                <span
                  style={{
                    color: theme.colors.textbox.placeholder
                  }}
                >
                  Source:
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name='ComputerScreen1' />
                  <span style={{ marginLeft: '0.5rem' }}>
                    {migrationStatus?.sourceIP}
                  </span>
                </div>
                <div>version {migrationStatus?.sourceBuildNum}</div>
              </div>
              <div style={{ margin: '0 1rem', display: 'flex' }}>
                <Icon name='ArrowRight' size='larger' />
              </div>
              <div>
                <span
                  style={{
                    color: theme.colors.textbox.placeholder
                  }}
                >
                  Target:
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name='ComputerScreen1' />
                  <span style={{ marginLeft: '0.5rem' }}>
                    {migrationStatus?.targetIP}
                  </span>
                </div>
                <div>version {migrationStatus?.targetBuildNum}</div>
              </div>
            </div>
            <div>{MIGRATION_STATUS_DISPLAY[migrationStatus?.state] || ''}</div>
            <Progress
              progress={4}
              total={4}
              color={
                migrationStatus?.state === MIGRATION_STATUS.FAILED
                  ? theme.colors.textbox.validationMessage
                  : theme.colors.brand
              }
            />
            {migrationStatus?.state ===
              MIGRATION_STATUS.DATA_TRANSFER_COMPLETED &&
              !migrationStatus?.isTargetNode && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Go to the new virtual appliance&apos;s Maintenance page, and
                  click Ack Upgrade to complete the upgrade.
                  <Button
                    href={`https://${migrationStatus?.targetIP}`}
                    variant='link'
                    icon={{ name: 'ComputerScreen2' }}
                  >
                    {migrationStatus?.targetIP}
                  </Button>
                </div>
              )}
            {migrationStatus?.state ===
              MIGRATION_STATUS.DATA_TRANSFER_COMPLETED &&
              migrationStatus?.isTargetNode && (
                <div style={{ marginTop: '0.5rem' }}>
                  <AckUpgrade />
                </div>
              )}

            {migrationStatus?.state === MIGRATION_STATUS.FAILED &&
              !migrationStatus?.isTargetNode && (
                <div style={{ marginTop: '0.5rem' }}>
                  <Link to={`${ROUTE_MAINTENANCE}`}>
                    Retry upgrade on maintenance page
                  </Link>
                </div>
              )}
          </Banner>
        </Outer>
      )}
    </>
  )
}
