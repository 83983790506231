import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Block from '@kaizen-ui/block'
import Button from '@kaizen-ui/button'
import Icon from '@kaizen-ui/icon'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { PageHeader, useFetch, useAppNotifications, FileUpload } from 'common'
import {
  API_SERVICE_INSTANCE_IDENTITY_TOKEN_UNAUTH,
  API_SERVICE_INSTANCE_UPGRADE
} from '../../api'
import { useAuth } from '../../hooks'

const BlockSection = styled.div`
  margin: 0;
`
const Step = styled.span`
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.textbox.placeholder};
  font-size: 1.2rem;
`
const BlockTitle = styled.h3`
  display: flex;
  align-items: center;
`
const BlockContents = styled.div`
  ${BlockSection}+${BlockSection} {
    margin-top: 2rem;
  }

  .kaizen-button.link {
    padding: 0;
  }
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  > div {
    margin-right: 1rem;
  }
`
const Warning = styled.div`
  display: flex;
  margin-top: 1rem;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
export const Upgrade = () => {
  const { notify } = useAppNotifications()
  const { checkAuth } = useAuth()
  const theme = useContext(KaizenThemeContext)
  const [upgradeFile, setUpgradeFile] = useState(undefined)
  const [downloading, setDownloading] = useState(false)
  const { getData } = useFetch({
    endpoint: API_SERVICE_INSTANCE_IDENTITY_TOKEN_UNAUTH,
    actionLabel: 'Download service instance token for upgrade'
  })
  const { getData: sendUpgrade, loading: upgrading } = useFetch({
    endpoint: API_SERVICE_INSTANCE_UPGRADE,
    actionLabel: 'Upgrade service instance',
    method: 'POST'
  })

  const downloadToken = async () => {
    setDownloading(true)
    const data = await getData()
    if (data?.identityToken) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'text/plain'
      })
      saveAs(
        blob,
        `dls_instance_token_${moment(Date.now()).format(
          'MM-DD-YYYY-HH-mm-ss'
        )}.tok`
      )
    } else {
      notify('Unable to parse token', null, true)
    }
    setDownloading(false)
  }

  const verifyFile = async uploadedFile => {
    setUpgradeFile(undefined)
    const reader = new FileReader()
    reader.onload = async function(r) {
      let result = null
      try {
        result = JSON.parse(r.target.result)
      } catch (e) {
        notify(
          'Could not process the upgrade file. Please select a valid upgrade file.',
          null,
          true,
          'Upgrade processing failed'
        )
      }
      if (result?.upgradeFile) {
        setUpgradeFile(result)
      }
    }
    reader.onerror = function(err) {
      const msg =
        'Could not process the upgrade file. Please select a valid upgrade file.'
      notify(msg, null, true, 'Upgrade processing failed')
    }
    reader.readAsText(uploadedFile)
  }

  const submitUpgrade = async () => {
    const data = await sendUpgrade({ body: upgradeFile })
    if (data) {
      notify(
        "We're upgrading your virtual appliance. Please check back in a few minutes.",
        null,
        null,
        'Upgrade in progress'
      )

      if (data.message) {
        notify(data.message, { autoClose: false }, null, 'Upgrade in progress')
      }
      checkAuth(() => {
        //onUpdate && onUpdate()
      })
    }
  }

  return (
    <>
      <PageHeader
        title='Virtual Appliance Upgrade'
        subTitle='Configure this DLS instance as an upgrade to an existing DLS instance'
      />
      <Block loading={upgrading || downloading}>
        <BlockContents>
          <BlockSection>
            <BlockTitle theme={theme}>
              <Step>Step 1 -</Step>
              Download the DLS instance token and register with the NVIDIA
              Licensing Portal
            </BlockTitle>
            <div>
              Download a DLS instance token and upload the same on the NVIDIA
              Licensing Portal. While uploading the DLS instance token, select
              the <b>For upgrade</b> option, then select the service instance to
              be upgraded.
            </div>
            <div style={{ marginTop: '1rem' }}>
              <Button
                icon={{ name: 'ActionsDownload' }}
                variant='link'
                //type='secondary'
                onClick={downloadToken}
                disabled={downloading}
              >
                Download DLS Instance Token
              </Button>
            </div>
          </BlockSection>
          <BlockSection>
            <BlockTitle theme={theme}>
              <Step>Step 2 -</Step>
              Download the upgrade file from the NVIDIA Licensing Portal
            </BlockTitle>
            Go to the <b>Service Instance</b> page of the NVIDIA Licensing
            Portal, find the service instance to be upgraded. Click on{' '}
            <b>Download Upgrade File</b> to download the respective upgrade
            file.
          </BlockSection>
          <BlockSection>
            <BlockTitle theme={theme}>
              <Step>Step 3 -</Step>
              Upload the upgrade file here
            </BlockTitle>
            Upload the upgrade file downloaded from the NVIDIA Licensing Portal
            to complete the virtual appliance upgrade. You&#39;ll be able to use
            your old virtual appliance&#39;s credentials to login post upgrade.
            {upgradeFile && (
              <Warning>
                <Icon name='StatusWarning' size='larger' />
                <span>
                  Current virtual appliance&#39;s data will be replaced with the
                  data from the upgrade file. Update the IP address of the
                  current virtual appliance to the IP address of the old virtual
                  appliance (using the <b>Configure IP Address</b> option on the{' '}
                  <b>Service Instance</b> page) once the upgrade is complete.
                </span>
              </Warning>
            )}
            <Buttons>
              <FileUpload
                accept='.bin'
                label='Select upgrade file'
                onChange={verifyFile}
                variant='link'
                type='primary'
                iconName='FileBin'
              />
              <Button
                disabled={!upgradeFile || upgrading}
                icon={{ name: 'ActionsUpload' }}
                onClick={submitUpgrade}
              >
                Upload upgrade file
              </Button>
            </Buttons>
          </BlockSection>
        </BlockContents>
      </Block>
    </>
  )
}
