import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { formatDateTime, Pill } from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Block from '@kaizen-ui/block'
import Icon from '@kaizen-ui/icon'
import Button from '@kaizen-ui/button'
import {
  HIGH_AVAILABILITY_STATE,
  HIGH_AVAILABILITY_STATE_DISPLAY_NAME,
  SI_STATES,
  SI_STATE_DISPLAY_NAME
} from '../../api'
import { useServiceInstanceTokenDownloader } from '.'
import { useAuth } from '../../hooks'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .kaizen-button {
    padding: 0 0.5rem;
  }
`
const StyledBlock = styled.div`
  > div[elevation] {
    overflow-y: auto;
    overflow-x: hidden;

    button {
      z-index: 0;
    }
  }
`

export const ServiceInstanceProperties = ({
  loading,
  si,
  haState,
  openHaConfig,
  isSimpleMode = false
}) => {
  const theme = useContext(KaizenThemeContext)
  const { user } = useAuth()
  const { isMarkedPreReg } = user || {}
  const siPending = useMemo(() => si?.state === SI_STATES.PENDING, [si])
  const {
    download,
    loading: downloadingIdentity
  } = useServiceInstanceTokenDownloader(si)

  return (
    <StyledBlock theme={theme}>
      <Block
        loading={loading}
        title={si?.name}
        titleIcon={{
          name: 'CustomNgcServer',
          //color: isEnabled ? theme.colors.brand : theme.colors.red500,
          size: 24
        }}
      >
        <div>
          <Row>
            <Pill label='Id' value={si?.xid} />
            <Pill label='Type' value='DLS' />
            {isMarkedPreReg && (
              <Pill
                label='Registration Method'
                value='Alternate registration for DLS on air-gapped networks'
              />
            )}
            <Pill
              label='State'
              value={
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  data-tip={
                    siPending
                      ? 'This service instance has not yet been registered in the NVIDIA Licensing Portal'
                      : ''
                  }
                >
                  {siPending && (
                    <Icon
                      name='StatusWarning'
                      color={theme.colors.orange500}
                      variant='solid'
                    />
                  )}
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      color: siPending ? theme.colors.orange500 : 'inherit'
                    }}
                  >
                    {SI_STATE_DISPLAY_NAME[si?.state]}
                  </span>
                  {siPending && (
                    <span
                      data-tip={
                        isMarkedPreReg
                          ? 'Appliance has been configured for alternate registration for DLS on air-gapped networks'
                          : ''
                      }
                    >
                      <Button
                        variant='link'
                        icon={{ name: 'ActionsDownload' }}
                        disabled={downloadingIdentity || isMarkedPreReg}
                        onClick={download}
                      >
                        Download DLS Instance Token
                      </Button>
                    </span>
                  )}
                </div>
              }
            />

            <Pill label='Created' value={formatDateTime(si?.createdAt)} />
            <Pill label='Modified' value={formatDateTime(si?.lastUpdatedAt)} />
          </Row>
          <Row>
            <Pill label='Description' value={si?.description} />
          </Row>
          <Row>
            <Pill
              label='High availability'
              value={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {HIGH_AVAILABILITY_STATE_DISPLAY_NAME[haState]}
                  {(haState === HIGH_AVAILABILITY_STATE.STANDALONE ||
                    haState === HIGH_AVAILABILITY_STATE.UNCONFIGURED) && (
                    <Button
                      variant='link'
                      icon={{ name: 'ConnectionNetworkComputers2' }}
                      onClick={openHaConfig}
                    >
                      Configure high availability
                    </Button>
                  )}
                </div>
              }
            />
            {!isSimpleMode ? (
              <Pill
                label='Instant cloning support'
                value={si?.instantCloneSupport ? 'Enabled' : 'Disabled'}
              />
            ) : null}
          </Row>
        </div>
      </Block>
    </StyledBlock>
  )
}
