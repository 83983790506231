import React from 'react'
import Result from '@kaizen-ui/result'
import Button from '@kaizen-ui/button'
import { useFetch, useAppNotifications, ModalButtons } from 'common'
import { API_SERVICE_INSTANCE_NODE_RESTART } from '../../api'

export const RestartNodeService = ({
  isCritical,
  isPrimary,
  onUpdate,
  isClustered
}) => {
  console.log('isClustered : '
  , JSON.stringify(isClustered));
  const serviceLabel = isCritical ? 'critical' : 'other'
  const { notify } = useAppNotifications()
  const { getData: sendRequest, loading, error } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NODE_RESTART,
    actionLabel: `Restart ${serviceLabel} services`,
    method: 'POST'
  })

  const submit = async () => {
    const body = {
      serviceType: isCritical ? 'CRITICAL' : 'OTHER'
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `${isPrimary ? 'Primary' : 'Secondary'} node ${
        isCritical ? 'critical' : 'other'
      } services restarted!`
      notify(msg, null, null, `Restarted ${serviceLabel} services`)
      onUpdate && onUpdate()
    }
  }
  return (
    <>
      <Result
        status={loading ? 'loading' : error ? 'error' : 'info'}
        title={
          loading
            ? `Restarting ${serviceLabel}`
            : error
            ? undefined
            : 'Are you sure?'
        }
        subTitle={
          loading
            ? undefined
            : error
            ? error
            : `This will restart ${serviceLabel} services on the ${
                isPrimary ? 'primary' : 'secondary'
              } node.${
                isClustered && isCritical
                  ? ' Restarting critical services can trigger a failover if it takes more than two seconds.'
                  : ''
              }`
        }
      />
      <ModalButtons>
        <Button onClick={submit} disabled={loading}>
          Restart Services
        </Button>
      </ModalButtons>
    </>
  )
}
