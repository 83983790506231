import React, { useEffect, useState, useGlobal } from 'reactn'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Button from '@kaizen-ui/button'
import Spinner from '@kaizen-ui/spinner'
import { useFetch, useAppNotifications } from 'common'
import { GLOBAL_SERVICE_INSTANCE_ID } from '../../globalState'
import { API_SERVICE_INSTANCE_NTP_SERVER } from '../../api'
import { SessionExpired } from '../../Components'

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;

  button {
    margin-left: 0.5rem;
  }
`
const DomainOrIpRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$|((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/

/*
const recursiveDomainOrIpRegex = /^(?<big>((?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?(,|$)|((\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*(,|$))|(\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*(,|$)))))(?&big)*(?<!,)$/
*/

const validationSchema = yup.object({
  ntpServer: yup
    .array()
    .transform(function(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue
        ? originalValue.split(',').map(val => val.trim())
        : []
    })
    .test(
      'Check validity of the list',
      'Each comma separated value must be a valid IP address or domain name',
      values => {
        return values?.length
          ? values.every(input => DomainOrIpRegex.test(input))
          : true
      }
    )
    .required('NTP server(s) field is required')
})

export const NTPServerConfig = ({ onUpdate }) => {
  const { notify } = useAppNotifications()
  const [serviceInstanceId] = useGlobal(GLOBAL_SERVICE_INSTANCE_ID)
  const [ntpServer, setNTPServer] = useState('')
  const [refreshToggle, setRefreshToggle] = useState(false)

  const { getData, abort } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NTP_SERVER,
    actionLabel: 'Get NTP server info',
    SessionExpired: SessionExpired,
    supressToast: true
  })

  const { getData: configureNTP, loading: configuring } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NTP_SERVER,
    actionLabel: 'Configure NTP server(s)',
    method: 'POST'
  })

  const { getData: resetNTP, loading: resetting } = useFetch({
    endpoint: `${API_SERVICE_INSTANCE_NTP_SERVER}?revert=true`,
    actionLabel: 'Reset NTP server to default',
    method: 'POST'
  })

  useEffect(() => {
    const getNTPconfig = async () => {
      const data = await getData()
      if (data?.ntpServer) {
        setNTPServer(data?.ntpServer)
      }
    }
    getNTPconfig()
    return () => {
      abort()
    }
  }, [refreshToggle]) //eslint-disable-line react-hooks/exhaustive-deps

  const initialValues = {
    ntpServer: ntpServer
  }

  const submit = async (values, actions) => {
    const { ntpServer } = values || {}
    const { resetForm } = actions || {}
    const body = {
      ntpServer: ntpServer.split(/[\s,]+/).join()
    }
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    configureNTP({ body, headers }).then(data => {
      if (data) {
        const msg = `NTP server(s) configured successfully!`
        notify(msg, null, null, 'Configured NTP server(s)')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      } else {
        resetForm({ values: initialValues })
      }
    })
  }

  const onResetToDefault = async () => {
    const headers = { 'x-nv-service-instance-id': serviceInstanceId }
    resetNTP({ body: {}, headers }).then(data => {
      if (data) {
        const msg = 'NTP server was reset to default successfully!'
        notify(msg, null, null, 'Reset NTP server to default')
        setRefreshToggle(val => !val)
        onUpdate && onUpdate()
      }
    })
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: submit
  })

  return (
    <div>
      <Textbox
        id='ntpServer'
        name='ntpServer'
        label='NTP Server(s)'
        placeholder='Domain name or IP address, enter comma separated values in case of multiple'
        className='nvl'
        value={formik.values.ntpServer}
        disabled={configuring}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.ntpServer && formik.errors.ntpServer}
      />
      <Buttons>
        {(configuring || resetting) && (
          <span style={{ marginRight: '0.5rem' }}>
            <Spinner size='tiny' />
          </span>
        )}
        <Button onClick={onResetToDefault} disabled={resetting || configuring}>
          Reset to Default
        </Button>

        <Button
          primary
          icon={{ name: 'TimeClock' }}
          onClick={formik.handleSubmit}
          disabled={configuring || resetting}
        >
          Configure
        </Button>
      </Buttons>
    </div>
  )
}
