import React, { useContext, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  Pill,
  useFetch,
  useAppNotifications,
  ModalButtons,
  toDaysMinutesSeconds
} from 'common'
import styled from 'styled-components'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import Icon from '@kaizen-ui/icon'
import Block from '@kaizen-ui/block'
import Button from '@kaizen-ui/button'
import Result from '@kaizen-ui/result'
import Modal from '@kaizen-ui/modal'
import {
  API_SERVICE_INSTANCE_CONFIGURE_HA,
  HIGH_AVAILABILITY_NODE_ROLE,
  HIGH_AVAILABILITY_NODE_STATUS
} from '../../api'
import { SessionExpired } from '../../Components'
import { EditNode } from './EditNode'
import { RestartNodeService } from './RestartNodeService'
import { Banner } from '@kaizen-ui/complete'

const StyledBlock = styled.div`
  > div[elevation] {
    overflow-y: auto;
    overflow-x: hidden;

    button {
      z-index: 0;
    }
  }
`

export const Health = ({
  primary,
  secondary,
  loading,
  currentLocation,
  onUpdate,
  isPureContainer,
  isClustered
}) => {
  const theme = useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const [removeOpen, setRemoveOpen] = useState(false)
  const [selectedNode, setSelectedNode] = useState(false)
  const [isPrimary, setIsPrimary] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const { getData: sendDelete, loading: deleting, error } = useFetch({
    endpoint: API_SERVICE_INSTANCE_CONFIGURE_HA,
    actionLabel: 'Remove node from HA cluster',
    method: 'DELETE',
    responseOnly: true,
    SessionExpired: SessionExpired
  })

  const removeNode = async () => {
    const ok = await sendDelete()
    if (ok) {
      notify(
        `The secondary node (${secondary.network_location}) was successfully removed!`,
        null,
        null,
        'Removed node from HA cluster'
      )

      setRemoveOpen(false)
      onUpdate && onUpdate()
    }
  }

  return (
    <>
      {!primary && !secondary && (
        <StyledBlock theme={theme}>
          <Block
            loading={loading}
            title='Node Health'
            titleIcon={{
              name: 'ComputerScreen2',
              //color: isEnabled ? theme.colors.brand : theme.colors.red500,
              size: 24
            }}
          >
            <div>unable to determine node health</div>
          </Block>
        </StyledBlock>
      )}
      {primary && (
        <StyledBlock theme={theme}>
          <Block
            loading={loading}
            title='Primary Node Health'
            titleIcon={{
              name: 'ComputerScreen2',
              color: theme.colors.brand,
              size: 24
            }}
            actions={
              !isPureContainer ? (
                <span>
                  <Button
                    variant='solid'
                    icon={{ name: 'ConnectionServerNetwork1' }}
                    type='secondary'
                    disabled={
                      primary.role?.toLowerCase() ===
                      HIGH_AVAILABILITY_NODE_ROLE.UNKNOWN.toLowerCase()
                    }
                    onClick={() => {
                      setSelectedNode(primary)
                      setIsPrimary(true)
                      setEditOpen(true)
                    }}
                  >
                    Configure IP address
                  </Button>
                </span>
              ) : null
            }
          >
            <NodeHealth
              node={primary}
              isPrimary
              isClustered={isClustered}
              showRestart={
                currentLocation === primary.network_location ||
                currentLocation === primary.ip_v6_address ||
                currentLocation === primary.fqdn
              }
              onUpdate={onUpdate}
            />
          </Block>
        </StyledBlock>
      )}
      {secondary && (
        <div style={{ marginTop: '1rem' }}>
          <StyledBlock theme={theme}>
            <Block
              actions={
                <>
                  {!isPureContainer ? (
                    <div>
                      <Button
                        variant='solid'
                        icon={{ name: 'ConnectionServerNetwork1' }}
                        type='secondary'
                        disabled={
                          secondary.role?.toLowerCase() ===
                          HIGH_AVAILABILITY_NODE_ROLE.UNKNOWN.toLowerCase()
                        }
                        onClick={() => {
                          setSelectedNode(secondary)
                          setEditOpen(true)
                        }}
                      >
                        Configure IP address
                      </Button>
                    </div>
                  ) : null}

                  {secondary?.down_time ? (
                    <StyledBanner>
                      <Banner
                        icon={{
                          name: 'StatusWarning',
                          size: 18,
                          variant: 'solid'
                        }}
                        rounded
                        status='critical'
                      >
                        <span style={{ fontSize: '14px' }}>
                          Inactive since last{' '}
                          {toDaysMinutesSeconds(secondary?.down_time)}
                        </span>
                      </Banner>
                    </StyledBanner>
                  ) : null}

                  <div data-tip='Remove this node from the high availability cluster'>
                    <Button
                      variant='solid'
                      type='secondary'
                      icon={{ name: 'ActionsTrash' }}
                      disabled={
                        currentLocation === secondary.network_location ||
                        currentLocation === secondary.ip_v6_address ||
                        currentLocation === secondary.fqdn ||
                        deleting
                      }
                      onClick={() => setRemoveOpen(true)}
                    >
                      Remove Node
                    </Button>
                  </div>
                </>
              }
              loading={loading}
              title='Secondary Node Health'
              titleIcon={{
                name: 'ComputerScreen2',
                ...(secondary?.down_time
                  ? {
                      color: theme.colors.red500
                    }
                  : {}),
                size: 24
              }}
            >
              <NodeHealth
                node={secondary}
                showRestart={
                  currentLocation === secondary.network_location ||
                  currentLocation === secondary.ip_v6_address ||
                  currentLocation === secondary.fqdn
                }
                onUpdate={onUpdate}
              />
            </Block>
          </StyledBlock>
        </div>
      )}
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Remove Node'
          subtitle='Remove the secondary node from the high availability cluster'
          open={removeOpen}
          onClose={() => setRemoveOpen(false)}
        >
          {removeOpen && (
            <>
              <Result
                status={deleting ? 'loading' : error ? 'error' : 'info'}
                title={
                  deleting
                    ? 'Removing node'
                    : error
                    ? undefined
                    : 'Are you sure?'
                }
                subTitle={
                  deleting
                    ? ''
                    : error
                    ? error
                    : 'This will remove the secondary node from HA cluster and the primary node will be configured as a standalone node. The secondary node will be cleaned up.'
                }
              />
              <ModalButtons>
                <Button disabled={deleting} onClick={removeNode}>
                  Remove Node
                </Button>
              </ModalButtons>
            </>
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Configure Node IP Address'
          subtitle="The IP address must be in the subnet range of the node's virtual network"
          open={editOpen}
          onClose={() => setEditOpen(false)}
        >
          {editOpen && (
            <EditNode
              node={selectedNode}
              isPrimary={isPrimary}
              onUpdate={() => {
                setEditOpen(false)
                onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  svg {
    margin-right: 0.5rem;
  }
  .kaizen-button {
    padding: 0 0 0 0.5rem;
  }
`
const Capitalize = styled.div`
  text-transform: capitalize;
`

const NodeHealth = ({
  node,
  isPrimary,
  showRestart,
  onUpdate,
  isClustered
}) => {
  const theme = useContext(KaizenThemeContext)
  const [restarCriticalOpen, setRestartCriticalOpen] = useState(false)
  const [restarOtherOpen, setRestartOtherOpen] = useState(false)

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <>
      <Row>
        <Pill
          label='Fully qualified domain name'
          value={node.fqdn || 'not available'}
        />
        <Pill
          label='IPv6 address'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {typeof node.ip_v6_address === 'string'
                ? node.ip_v6_address
                : node.ip_v6_address?.length > 0
                ? node.ip_v6_address?.join(', ')
                : 'not assigned'}
            </div>
          }
        />
        <Pill
          label='IPv4 address'
          value={node.network_location || 'not assigned'}
        />
      </Row>
      <Row>
        <Pill
          label='Critical services'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={
                  node.status['Critical Services'] ===
                  HIGH_AVAILABILITY_NODE_STATUS.ACTIVE
                    ? 'StatusCircleCheck1'
                    : 'StatusCircleError'
                }
                color={
                  node.status['Critical Services'] ===
                  HIGH_AVAILABILITY_NODE_STATUS.ACTIVE
                    ? theme.colors.brand
                    : theme.colors.textbox.validationMessage
                }
                variant='solid'
              />
              <Capitalize
                style={{
                  color:
                    node.status['Critical Services'] ===
                    HIGH_AVAILABILITY_NODE_STATUS.ACTIVE
                      ? theme.colors.brand
                      : theme.colors.textbox.validationMessage
                }}
              >
                {node.status['Critical Services'] ||
                  HIGH_AVAILABILITY_NODE_ROLE.UNKNOWN}
              </Capitalize>
              <span
                data-tip={
                  showRestart
                    ? `Restarts 'Critical services' for currently logged-in node.`
                    : `Restart option is available only for the services of the currently logged-in node. Please login to the respective node to be able to restart its services.`
                }
              >
                <Button
                  disabled={!showRestart}
                  variant='link'
                  icon={{ name: 'ActionsSync' }}
                  type='secondary'
                  onClick={() => setRestartCriticalOpen(true)}
                >
                  Restart
                </Button>
              </span>
            </div>
          }
        />
        <Pill
          label='Other services'
          value={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                name={
                  node.status['Other Services'] ===
                  HIGH_AVAILABILITY_NODE_STATUS.ACTIVE
                    ? 'StatusCircleCheck1'
                    : 'StatusCircleError'
                }
                color={
                  node.status['Other Services'] ===
                  HIGH_AVAILABILITY_NODE_STATUS.ACTIVE
                    ? theme.colors.brand
                    : theme.colors.textbox.validationMessage
                }
                variant='solid'
              />
              <Capitalize
                style={{
                  color:
                    node.status['Other Services'] ===
                    HIGH_AVAILABILITY_NODE_STATUS.ACTIVE
                      ? theme.colors.brand
                      : theme.colors.textbox.validationMessage
                }}
              >
                {node.status['Other Services'] ||
                  HIGH_AVAILABILITY_NODE_ROLE.UNKNOWN}
              </Capitalize>
              <span
                data-tip={
                  showRestart
                    ? `Restarts 'Other services' for currently logged-in node.`
                    : `Restart option is available only for the services of the currently logged-in node. Please login to the respective node to be able to restart its services.`
                }
              >
                <Button
                  disabled={!showRestart}
                  variant='link'
                  icon={{ name: 'ActionsSync' }}
                  type='secondary'
                  onClick={() => setRestartOtherOpen(true)}
                >
                  Restart
                </Button>
              </span>
            </div>
          }
        />
      </Row>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Restart Critical Services'
          //subtitle=
          open={restarCriticalOpen}
          onClose={() => setRestartCriticalOpen(false)}
        >
          {restarCriticalOpen && (
            <RestartNodeService
              isCritical
              isPrimary={isPrimary}
              isClustered={isClustered}
              onUpdate={() => {
                setRestartCriticalOpen(false)
                onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <Modal
          title='Restart Other Services'
          //subtitle=
          open={restarOtherOpen}
          onClose={() => setRestartOtherOpen(false)}
        >
          {restarOtherOpen && (
            <RestartNodeService
              isPrimary={isPrimary}
              onUpdate={() => {
                setRestartOtherOpen(false)
                onUpdate()
              }}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export const StyledBanner = styled.div`
  align-self: center;
  > div {
    display: inline-flex;
    padding: 0.4rem 0.8rem;
  }

  .banner-icon {
    margin-right: 0.4rem;
  }
`
