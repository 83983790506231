import React, { useContext, useGlobal } from 'reactn'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import {
  ROUTE_DASHBOARD,
  ROUTE_SETUP,
  ROUTE_LOGIN,
  ROUTE_SUPPORT,
  ROUTE_SET_PASSWORD,
  ROUTE_SERVICE_INSTANCES,
  ROUTE_LEASES,
  ROUTE_METRICS,
  ROUTE_EVENTS,
  ROUTE_UPGRADE,
  ROUTE_MAINTENANCE,
  ROUTE_SETTINGS
} from '../AppRouter'
// import {
//   //DEV_MODE,
//   USER_PERMISSIONS
// } from '../../api'
import { useAuth } from '../../hooks'
import { Menu } from 'common'
import { GLOBAL_OFFLINE_UPGRADE_INPROGRESS } from '../../globalState'

const menuItems = [
  {
    title: 'Login',
    href: ROUTE_LOGIN,
    icon: 'AccountCircleGenericUser',
    auth: false
  },
  {
    title: 'Dashboard',
    href: ROUTE_DASHBOARD,
    icon: 'ObjectsHome',
    auth: true,
    serverless: true
  },
  {
    title: 'Setup',
    href: ROUTE_SETUP,
    icon: 'AccountCircle',
    setupRequired: true,
    children: [
      {
        title: 'New Install',
        href: ROUTE_SET_PASSWORD,
        icon: 'DatabaseAdd',
        setupRequired: true
      },
      {
        title: 'Upgrade',
        href: ROUTE_UPGRADE,
        icon: 'DatabaseStar',
        setupRequired: true
      }
    ]
  },
  {
    title: 'Service Instance',
    href: ROUTE_SERVICE_INSTANCES,
    icon: 'CustomNgcServer',
    auth: true,
    serverless: true
  },
  {
    title: 'Events',
    href: ROUTE_EVENTS,
    icon: 'ViewList',
    serverRequired: true,
    auth: true
  },
  {
    title: 'Leases',
    href: ROUTE_LEASES,
    serverRequired: true,
    icon: 'CustomNgcWorkstation',
    auth: true
  },
  {
    title: 'Metrics',
    href: ROUTE_METRICS,
    serverRequired: true,
    icon: 'EditCheckList',
    auth: true
  },
  {
    title: 'Settings',
    href: ROUTE_SETTINGS,
    icon: 'SettingsCog',
    serverRequired: true,
    auth: true
  },
  {
    title: 'Maintenance',
    href: ROUTE_MAINTENANCE,
    icon: 'FileDatabase',
    auth: true,
    serverRequired: true,
    offline: true
  },
  {
    title: 'Support',
    href: ROUTE_SUPPORT,
    icon: 'MessengerCircleDouble',
    always: true,
    offline: true
  }

  // {
  //   title: 'Development',
  //   href: ROUTE_DEVTOOLS,
  //   icon: 'AwardsCrown1',
  //   always: DEV_MODE
  // }
]

export const SideNav = () => {
  const { user, permissions, setupRequired, serverId } = useAuth()
  const theme = useContext(KaizenThemeContext)
  const [offlineUpgrade] = useGlobal(GLOBAL_OFFLINE_UPGRADE_INPROGRESS)

  const filteredMenuItems = React.useMemo(
    () =>
      menuItems.filter(mi =>
        offlineUpgrade
          ? mi.offline
          : setupRequired
          ? mi.always || mi.setupRequired
          : !serverId && !!user
          ? mi.serverless || mi.always
          : (mi.auth === !!user &&
              (!mi.requiredAction ||
                permissions.includes(mi.requiredAction))) ||
            mi.always
      ),
    [user, setupRequired, serverId, offlineUpgrade] //eslint-disable-line react-hooks/exhaustive-deps
  )

  return <Menu items={filteredMenuItems} theme={theme} />
}
