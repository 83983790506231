import React, { useContext, createContext, useGlobal } from 'reactn'
import serialize from 'serialize-javascript'
import { API_LOGIN, API_LOGOUT, API_USERS_ME, DEV_MODE } from '../../api'
import { useFetch } from 'common'
import {
  GLOBAL_IS_UNREGISTERED_USER,
  GLOBAL_OFFLINE_UPGRADE_INPROGRESS,
  GLOBAL_ORG,
  GLOBAL_SERVER_DOWN,
  GLOBAL_SERVER_ID,
  GLOBAL_SERVICE_INSTANCE_ID,
  GLOBAL_USER,
  GLOBAL_VIRTUAL_GROUP
} from '../../globalState'
import { SessionExpired } from '../../Components'

const authContext = createContext()

export const ProvideAuth = ({ children }) => {
  const auth = useAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export function useAuthContext() {
  return useContext(authContext)
}

export function useAuth() {
  const [user, setUser] = useGlobal(GLOBAL_USER)
  const [serverId, setServerId] = useGlobal(GLOBAL_SERVER_ID)
  const [serviceInstanceId, setServiceInstanceId] = useGlobal(
    GLOBAL_SERVICE_INSTANCE_ID
  )
  const [, setOrg] = useGlobal(GLOBAL_ORG)
  const [virtualGroup, setVirtualGroup] = useGlobal(GLOBAL_VIRTUAL_GROUP)
  const [setupRequired, setSetupRequired] = useGlobal(
    GLOBAL_IS_UNREGISTERED_USER
  )
  const [, setServerDown] = useGlobal(GLOBAL_SERVER_DOWN)

  const [, setOfflineUpgrade] = useGlobal(GLOBAL_OFFLINE_UPGRADE_INPROGRESS)
  const vgId = virtualGroup && virtualGroup.id
  const isNvAdmin = user && user.orgName === '*'
  const permissions = !user
    ? []
    : isNvAdmin
    ? user.userScope?.[0]?.actions
    : user.userScope?.find(group => group.virtualGroupId === vgId)?.actions
  const role = user
    ? isNvAdmin
      ? user.userScope?.[0]?.role
      : user.userScope?.find(group => group.virtualGroupId === vgId)?.role
    : ''
  const { getData: getMe, error: meError, resetError: resetMeError } = useFetch(
    {
      endpoint: API_USERS_ME,
      actionLabel: 'Determine user permissions',
      SessionExpired: SessionExpired,
      supressToast: true,
      returnResponse: true,
      isCheckAuth: true,
      onMeDown: () => {
        setServerDown(true)
      }
    }
  )

  const signin = (body, cb) => {
    const login = async function() {
      const endpoint = API_LOGIN
      //const { username, password } = body
      const options = {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: serialize(body)
      }
      const response = await fetch(endpoint, options)

      if (response.ok) {
        const loc = response.headers.get('location')
        if (loc) {
          window.location.href = loc
          return
        }
        // setting auth token in localstorage to be able to login while running in development
        if (DEV_MODE) {
          const { token } = await response.json()
          localStorage.setItem('nvl::dev', token)
        }
        checkAuth(cb)
      } else {
        setUser(null)
        let resp = null
        try {
          resp = await response?.json()
        } catch (e) {
          console.log(e)
        }
        cb({ success: false, ...resp })
      }
    }
    login()
  }

  const signout = cb => {
    const logout = async function() {
      const endpoint = API_LOGOUT
      const options = { credentials: 'include', method: 'POST' }
      const response = await fetch(endpoint, options)

      if (!response) {
        setUser(null)
        if (cb) cb()
      }

      if (response.ok) {
        const loc = response.headers.get('location')
        if (loc) {
          window.location.href = loc
          return
        } else {
          setUser(null)
          if (cb) cb()
        }
      } else {
        setUser(null)
        if (cb) cb()
      }
    }
    logout()
  }

  const checkAuth = async cb => {
    setServerDown(false)
    resetMeError()
    const me = await getMe()

    if (me && !me.responseStatus) {
      const label = me.orgDescription
        ? `${me.orgDescription} (${me.orgName})`
        : me.orgName
      if (me.orgName) {
        setOrg({ id: me.orgName, value: me.orgName, label: label })
      } else {
        setOrg(null)
      }

      const defaultGroup = me.userScope.find(
        vg => vg.virtualGroupName === 'DEFAULT_VG'
      )
      const ls = localStorage.getItem('nvl::virtual-group')
      const matchingGroup = me.userScope.find(
        d => d.virtualGroupId === parseInt(ls)
      )
      const group = matchingGroup || defaultGroup || me.userScope[0]
      if (group.virtualGroupId) {
        setVirtualGroup({
          id: group.virtualGroupId,
          value: group.virtualGroupId,
          label:
            group.virtualGroupName === 'DEFAULT_VG'
              ? `Group ${label}`
              : group.virtualGroupName,
          name: group.virtualGroupName,
          isDefault: group.virtualGroupName === 'DEFAULT_VG'
        })
      } else {
        setVirtualGroup(null)
      }

      setOfflineUpgrade(me.offlineUpgradeTriggered)
      setServiceInstanceId(group.serviceInstanceId)
      setServerId(group.licenseServerId)
      setUser(me).then(() => cb({ success: true, user: me }))
      setSetupRequired(false)
    } else {
      if (me?.responseStatus === 412) {
        setUser(null).then(() => cb({ success: false, unregistered: true }))
        setSetupRequired(true)
      } else {
        setUser(null).then(() => cb({ success: false }))
        setSetupRequired(false)
      }

      /*else if (me?.responseStatus === 401) {
        setUser(null).then(() => cb({ success: false }))
        setSetupRequired(false)
      } else {
        setUser(null).then(() => cb({ success: false }))
        setServerDown(true)
      }*/
    }
  }

  return {
    user,
    setupRequired,
    serverId,
    serviceInstanceId,
    permissions,
    role,
    signin,
    signout,
    checkAuth,
    meError,
    resetMeError
  }
}
