import React, { useContext, useGlobal, useEffect, useState } from 'reactn'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import styled from 'styled-components'
import Progress from '@kaizen-ui/progress'
import Icon from '@kaizen-ui/icon'
import Note from '@kaizen-ui/note'
import Text from '@kaizen-ui/text'
import Button, { ButtonGroup } from '@kaizen-ui/button'
import Modal from '@kaizen-ui/modal'
import { GLOBAL_OFFLINE_UPGRADE_INPROGRESS } from '../../globalState'
import { BlockContents, BlockSection, BlockTitle, Step } from './Upgrade'
import { useAppNotifications, useFetch, formatDateTime } from 'common'
import {
  API_MIGRATION_OFFLINE_DECOMMISSION_SI,
  API_MIGRATION_OFFLINE_DOWNLOAD,
  API_MIGRATION_OFFLINE_GENERATE,
  API_MIGRATION_OFFLINE_STATUS,
  OFFLINE_MIGRATION_STATUS
} from '../../api'
import { SessionExpired } from '../../Components'
import { useAuth, useServiceInstance } from '../../hooks'

const Warning = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`
const StyledNote = styled.div`
  display: flex;
  align-items: center;

  > div {
    align-items: center;
    padding: 0;

    .note-command-container {
      padding-left: 0;
    }
  }
`
const SiDetails = styled.div`
  margin: 0.5rem 1.75rem;
`

export const OfflineUpgrade = () => {
  const theme = useContext(KaizenThemeContext)
  const { notify } = useAppNotifications()
  const { checkAuth } = useAuth()
  const [startOpen, setStartOpen] = useState(false)
  const [ackOpen, setAckOpen] = useState(false)
  const [offlineUpgrade] = useGlobal(GLOBAL_OFFLINE_UPGRADE_INPROGRESS)
  const [migrationStatus, setMigrationStatus] = useState(undefined)
  const [refresh, setRefresh] = useState(false)
  const { user } = useAuth()
  const { serviceInstance } = useServiceInstance({ autoLoad: true })

  const { getData, abort } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_STATUS,
    actionLabel: 'Check offline migration status',
    SessionExpired: SessionExpired,
    supressToast: true
  })
  const { getData: sendGenerate } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_GENERATE,
    actionLabel: 'Generate offline migration file',
    method: 'PUT',
    SessionExpired: SessionExpired
  })
  const { getData: sendDownload, loading: downloading } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_DOWNLOAD,
    actionLabel: 'download offline migration file',
    SessionExpired: SessionExpired
  })
  const { getData: sendDecom, loading: decomming } = useFetch({
    endpoint: API_MIGRATION_OFFLINE_DECOMMISSION_SI,
    actionLabel: 'Acknowledge offline migration file downloaded',
    method: 'DELETE',
    SessionExpired: SessionExpired
  })

  useEffect(() => {
    if (!offlineUpgrade) return

    const interval = setInterval(() => {
      getStatus()
    }, 5000)
    const getStatus = async () => {
      if (!user) {
        clearInterval(interval)
        return
      }
      const data = await getData()

      if (!data || !data.status) {
        //setMigrationStatus(undefined)
        clearInterval(interval)
        return
      }

      const { status } = data
      setMigrationStatus(data)
      if (
        status === OFFLINE_MIGRATION_STATUS.FAILED ||
        status === OFFLINE_MIGRATION_STATUS.COMPLETED
      ) {
        clearInterval(interval)
      }
    }
    getStatus()

    return () => {
      clearInterval(interval)
      abort()
    }
  }, [user, refresh, offlineUpgrade]) // eslint-disable-line react-hooks/exhaustive-deps

  const generateFile = async () => {
    const data = await sendGenerate()

    if (data) {
      notify(
        'Migration file generation triggered successfully',
        null,
        null,
        'Generated offline migration file'
      )
      checkAuth(() => {})
    }
    setRefresh(val => !val)
  }

  const downloadFile = async () => {
    const data = await sendDownload()

    if (data) {
      if (data?.migrationFile) {
        const blob = new Blob([JSON.stringify(data)], {
          type: 'application/octet-stream'
        })
        saveAs(
          blob,
          `on_prem_migration_file_${moment(Date.now()).format(
            'MM-DD-YYYY-HH-mm-ss'
          )}.bin`
        )
      } else {
        const msg =
          'Error with the response from licensing service. Please try again later.'
        notify(msg, null, null, 'Download migration file failed')
      }
    }
  }

  const ackDownload = async () => {
    const data = await sendDecom()

    if (data) {
      notify(
        'Acknowledged the download of migration file successfully. This virtual appliance will be switched off in few minutes',
        null,
        null,
        'Acknowledged offline migration file downloaded'
      )
    }
  }

  return (
    <>
      {!offlineUpgrade && (
        <Warning>
          <Icon
            name='StatusWarning'
            size='larger'
            color={theme.colors.orange500}
          />
          <span style={{ color: theme.colors.textbox.placeholder }}>
            This virtual appliance will be unusable once the offline upgrade
            process has started!
          </span>
        </Warning>
      )}
      <BlockContents>
        <BlockSection>
          <BlockTitle theme={theme}>
            <Step>Step 1 -</Step>
            Download Migration file
          </BlockTitle>
          <div>
            Generate and download the migration file. You won&apos;t be able to
            modify this virtual appliance or perform any lease operations once
            you start the migration file generation.
          </div>

          {migrationStatus?.status === OFFLINE_MIGRATION_STATUS.FAILED ? (
            <>
              <h4 style={{ color: theme.colors.red500, marginBottom: 0 }}>
                Failed to generate the migration file, please try again
              </h4>
              <Progress
                size='small'
                progress={1}
                total={1}
                color={theme.colors.red500}
              />
            </>
          ) : null}

          {(!offlineUpgrade ||
            migrationStatus?.status === OFFLINE_MIGRATION_STATUS.FAILED) && (
            <>
              <Button
                icon={{ name: 'DatabaseEdit' }}
                onClick={() => setStartOpen(true)}
              >
                Generate Migration File
              </Button>
            </>
          )}

          {migrationStatus?.status === OFFLINE_MIGRATION_STATUS.INITIATED && (
            <>
              <h4 style={{ marginBottom: 0 }}>
                Migration file generation is in progress. You&#39;ll be able to
                download the file once the migration file is available
              </h4>
              <Progress indeterminate size='small' />
            </>
          )}
          {migrationStatus?.status === OFFLINE_MIGRATION_STATUS.COMPLETED && (
            <>
              <h4 style={{ marginBottom: 0 }}>
                Migration file has been generated
              </h4>
              <Progress size='small' progress={1} total={1} />
              <SiDetails>
                <Text textStyle='label'>Service instance details:</Text>
                <StyledNote>
                  <Note copyValue={serviceInstance?.name}>
                    {serviceInstance?.name}
                  </Note>
                </StyledNote>
                <StyledNote>
                  <Note copyValue={serviceInstance?.xid}>
                    {serviceInstance?.xid}
                  </Note>
                </StyledNote>

                <div style={{ color: theme.colors.textbox.placeholder }}>
                  {formatDateTime(serviceInstance?.createdAt)}
                </div>
                <div style={{ display: 'flex' }}>
                  <Text truncate color={theme.colors.textbox.placeholder}>
                    {serviceInstance?.description}
                  </Text>
                </div>
              </SiDetails>
              <Warning style={{ margin: '1rem' }}>
                <Icon
                  name='StatusWarning'
                  size='larger'
                  color={theme.colors.red500}
                />
                <span style={{ color: theme.colors.textbox.placeholder }}>
                  Clicking &apos;Acknowledge Download&apos; acknowledges that
                  you have successfully downloaded the migration file and shuts
                  down this virtual appliance!
                </span>
              </Warning>

              <ButtonGroup>
                <Button
                  icon={{ name: 'ActionsDownload' }}
                  onClick={downloadFile}
                  disabled={downloading}
                >
                  Download Migration File
                </Button>
                <Button
                  icon={{ name: 'ActionsDownload' }}
                  onClick={() => setAckOpen(true)}
                  type='critical'
                  disabled={decomming}
                >
                  Acknowledge Download
                </Button>
              </ButtonGroup>
            </>
          )}
        </BlockSection>
        <BlockSection>
          <BlockTitle theme={theme}>
            <Step>Step 2 -</Step>
            Upload the Migration file on NVIDIA Licensing Portal
          </BlockTitle>
          In the NVIDIA Licensing Portal, go to the <b>
            Service Instances
          </b>{' '}
          page. Find the service instance which needs an upgrade, then click{' '}
          <b>Upload Migration File</b>. In the <b>Upload Migration File</b>{' '}
          pop-up window that opens, select the file generated in step one, and
          then upload.
        </BlockSection>
        <BlockSection>
          <BlockTitle theme={theme}>
            <Step>Step 3 -</Step>
            Set up a new virtual appliance
          </BlockTitle>
          Install a new version of the virtual appliance on a virtual machine.
          Once you are able to see the web interface for the virtual appliance,
          click on <b>Upgrade</b>. From the <b>Upgrade</b> page, download the
          DLS instance token for this new virtual appliance.
        </BlockSection>
        <BlockSection>
          <BlockTitle theme={theme}>
            <Step>Step 4 -</Step>
            Download the Upgrade file from NVIDIA Licensing Portal
          </BlockTitle>
          In the NVIDIA Licensing Portal, go to the <b>
            Service Instances
          </b>{' '}
          page. Click on <b>Upload DLS Instance Token</b>. In the{' '}
          <b>Upload DLS Instance Token</b> pop-up window that opens, select the{' '}
          <b>For upgrade</b> checkbox, select the service instance which needs
          an upgrade, and then submit. After uploading the token, download the
          respective upgrade file by clicking on <b>Download Upgrade File</b>.
        </BlockSection>
        <BlockSection>
          <BlockTitle theme={theme}>
            <Step>Step 5 -</Step>
            Upload the Upgrade file on the new virtual appliance setup
          </BlockTitle>
          Open the web interface of the new virtual appliance setup. Click{' '}
          <b>Upgrade</b>. Upload the upgrade file obtained in step four to
          complete the upgrade. You&#39;ll be able to use your old virtual
          appliance&#39;s credentials to login after the upgrade completion.
        </BlockSection>
      </BlockContents>
      <Modal
        title='Generate Migration File'
        subtitle='This will start the offline virtual appliance upgrade process'
        open={startOpen}
        onClose={() => setStartOpen(false)}
        primaryButtonText='Generate Migration File'
        onPrimaryButtonClick={() => generateFile()}
      >
        <ul>
          <li>
            The license configuration of current virtual appliance will be
            exported to a migration file, events will not be exported.
          </li>
          <li>
            If the license server is enabled for leasing operations, it will be
            disabled.
          </li>
          <li>
            The modifications to this virtual appliance will be blocked until
            the offline upgrade process is complete.
          </li>
        </ul>
      </Modal>
      <Modal
        title='Acknowledge Download'
        open={ackOpen}
        onClose={() => setAckOpen(false)}
        primaryButtonText='Acknowledge Download'
        onPrimaryButtonClick={() => ackDownload()}
      >
        <p>
          Acknowledges that you have successfully downloaded the migration file
          and decommissions this virtual appliance to free up the resources.
          Decommissioning the virtual appliance may take few minutes.
        </p>
      </Modal>
    </>
  )
}
