import React from 'react'
import { PageHeader } from 'common'
import { EmailAlerts } from './EmailAlerts'
import styled from 'styled-components'
import { ApiKeys } from './ApiKeys'
import { ServiceInstanceSettings } from '../ServiceInstance/ServiceInstanceSettings'
import { useAuth, useServiceInstance } from '../../hooks'
import { LogArchivalSetting } from '../ServiceInstance/LogArchivalSetting'
import { SSLConfiguration } from '../ServiceInstance/SSLConfiguration'
import { NTPServerConfig } from '../ServiceInstance/NTPServerConfig'
import { CollapsibleBlock } from 'common'
import { Text } from '@kaizen-ui/complete'

const StyledSetting = styled.div`
  .eOAMak {
    margin-bottom: 14px;
  }
`

const SettingSubTitle = ({ subtitle }) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Text textStyle={'p1'}>{subtitle}</Text>
    </div>
  )
}

export const Settings = () => {
  const { user } = useAuth()
  const { isPureContainer } = user || {}
  const { serviceInstance } = useServiceInstance({ autoLoad: true })
  return (
    <>
      <PageHeader
        title='Service Instance Settings'
        subTitle='View and configure various configuration options'
      ></PageHeader>
      <StyledSetting>
        <CollapsibleBlock title='Basic Settings'>
          <SettingSubTitle
            subtitle={
              'Settings defined at Service Instance level applicable to License Servers, if these settings are not overridden at License Server level. Also, includes appliance service log deletion settings.'
            }
          />
          <ServiceInstanceSettings serviceInstance={serviceInstance} />
        </CollapsibleBlock>
        {!isPureContainer && (
          <CollapsibleBlock title='Log Archival'>
            <SettingSubTitle
              subtitle={
                'Logs generated by DLS Services can be exported periodically to configured network path.'
              }
            />
            <LogArchivalSetting />
          </CollapsibleBlock>
        )}
        <CollapsibleBlock title='SSL Configuration'>
          <SettingSubTitle
            subtitle={
              'Configure SSL certificate and associated private key for HTTPS communication to DLS Appliance.'
            }
          />

          <SSLConfiguration />
        </CollapsibleBlock>
        {!isPureContainer && (
          <CollapsibleBlock title='NTP Server Configuration'>
            <SettingSubTitle
              subtitle={
                'Network Time Protocol (NTP) synchronizes DLS clock time across a network. When NTP is configured, DLS Appliance contacts an NTP server to synchronize its time.'
              }
            />

            <NTPServerConfig />
          </CollapsibleBlock>
        )}
        <CollapsibleBlock title='E-mail Alerts'>
          <SettingSubTitle
            subtitle={
              'Configure and store SMTP server details to enable email alerts.'
            }
          />
          <EmailAlerts />
        </CollapsibleBlock>
        <CollapsibleBlock title='API keys'>
          <SettingSubTitle
            subtitle={
              'List of API Keys that can be used to hit endpoints on this DLS Appliance. Check details for expiry and access limitations. Software Download access type is not applicable to DLS.'
            }
          />
          <ApiKeys />
        </CollapsibleBlock>
      </StyledSetting>
    </>
  )
}
