import React from 'react'
import { useFormik } from 'formik'
import Button from '@kaizen-ui/button'
import Textbox from '@kaizen-ui/textbox'
import Icon from '@kaizen-ui/icon'
import Text from '@kaizen-ui/text'
import * as Yup from 'yup'
import {
  useAppNotifications,
  useFetch,
  ModalSpinner,
  ModalButtons
} from 'common'
import { API_SERVICE_INSTANCE_NODE_IP } from '../../api'

const initialValues = {
  network_location: '',
  gateway: '',
  dns_server_one: '',
  dns_server_two: '',
  netmask_prefix: ''
}
const IPRegex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
const IPv4Regex = /(^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)/
export const EditNode = ({ node, isPrimary, onUpdate }) => {
  const { notify } = useAppNotifications()

  const validationSchema = Yup.object({
    network_location: Yup.string()
      .required('Static IP address is required')
      .notOneOf(
        [node ? node.network_location : ''],
        "Value can't be same as existing IP"
      )
      .matches(IPRegex, 'Must be a valid IP address'),
    gateway: Yup.string().matches(IPRegex, 'Must be a valid IP address'),
    dns_server_one: Yup.string().matches(IPRegex, 'Must be a valid IP address'),
    dns_server_two: Yup.string().matches(IPRegex, 'Must be a valid IP address'),
    netmask_prefix: Yup.number()
      .typeError('Must be a valid number')
      .integer('Must be a positive non-fractional number')
      .when('network_location', {
        is: val => IPv4Regex.test(val),
        then: Yup.number()
          .typeError('Must be a valid number')
          .min(2, 'Must be a number from 2 to 64')
          .max(64, 'Must be a number from 2 to 64')
      })
      .when('gateway', {
        is: val => !!val,
        then: Yup.number()
          .typeError('Must be a valid number')
          .required('Netmask prefix is required')
      })
      .min(2, 'Must be a number from 2 to 128')
      .max(128, 'Must be a number from 2 to 128')
  })

  const { getData: sendRequest, loading } = useFetch({
    endpoint: API_SERVICE_INSTANCE_NODE_IP,
    actionLabel: 'Set static IP for node',
    method: 'PATCH'
  })
  const submit = async () => {
    const {
      network_location,
      gateway,
      dns_server_one,
      dns_server_two,
      netmask_prefix
    } = formik.values

    const body = {
      updateTo: network_location,
      current: node?.network_location || node?.ip_v6_address || '',
      ...(gateway && { gateway }),
      ...(dns_server_one && { dns_server_one }),
      ...(dns_server_two && { dns_server_two }),
      ...(netmask_prefix && { netmask_prefix })
    }

    const result = await sendRequest({ body })
    if (result) {
      const msg = `Static IP configuration triggered successfully. The node will be accessible via the new IP address '${network_location}' after few minutes.`

      notify(
        msg,
        null,
        null,
        `Set static IP for ${isPrimary ? 'primary' : 'secondary'} node`
      )
      onUpdate && onUpdate()
    }
  }
  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: submit
  })

  return (
    <div>
      {loading && <ModalSpinner />}
      <Textbox
        id='network_location'
        name='network_location'
        label='Static IP Address'
        className='nvl'
        placeholder='Static IP address to be allocated to the node'
        value={formik.values.network_location}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.network_location && formik.errors.network_location
        }
      />
      <Textbox
        id='gateway'
        name='gateway'
        label='Gateway (Will use DHCP settings if left blank)'
        className='nvl'
        placeholder='Gateway that should be used by the VM'
        value={formik.values.gateway}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.gateway && formik.errors.gateway}
      />
      <Textbox
        id='netmask_prefix'
        name='netmask_prefix'
        label='Netmask Prefix (CIDR notation e.g. 24 for 255.255.255.0, 28 for 255.255.255.240)'
        className='nvl'
        placeholder='Netmask Prefix'
        value={formik.values.netmask_prefix}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.netmask_prefix && formik.errors.netmask_prefix
        }
      />
      <Textbox
        id='dns_server_one'
        name='dns_server_one'
        label='DNS Server'
        className='nvl'
        placeholder='DNS Server One'
        value={formik.values.dns_server_one}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.dns_server_one && formik.errors.dns_server_one
        }
      />
      <Textbox
        id='dns_server_two'
        name='dns_server_two'
        label='DNS Server'
        className='nvl'
        placeholder='DNS Server Two'
        value={formik.values.dns_server_two}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={
          formik.touched.dns_server_two && formik.errors.dns_server_two
        }
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <span style={{ marginRight: '0.5rem' }}>
          <Icon name='StatusWarning' />
        </span>
        <Text textStyle='p2'>
          The configuration takes a few minutes to complete after triggering
        </Text>
      </div>
      <ModalButtons>
        <Button disabled={loading} onClick={formik.handleSubmit}>
          Set Node IP Address
        </Button>
      </ModalButtons>
    </div>
  )
}
