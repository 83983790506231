import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import { Block } from '@kaizen-ui/complete'
import Radio, { RadioGroup } from '@kaizen-ui/radio'
import { HIGH_AVAILABILITY_NODE_ROLE, IP_ADDRESS_TYPE } from '../../api'

const StyledBlock = styled.div`
  padding: 7px;
  > div {
    padding: 14px;
  }
`

export const NodeSection = ({ node, onSelectionChange, index, selection }) => {
  const theme = useContext(KaizenThemeContext)
  const { fqdn, network_location, ip_v6_address, role, down_time } = node || {}
  const [addressType, setAddressType] = useState(
    selection?.addressType ||
      (fqdn
        ? 'FQDN'
        : ip_v6_address
        ? IP_ADDRESS_TYPE.IPV6
        : IP_ADDRESS_TYPE.IPV4)
  )

  useEffect(() => {
    onSelectionChange({
      role,
      addressType,
      ...(addressType === 'FQDN'
        ? { address: fqdn }
        : addressType === IP_ADDRESS_TYPE.IPV6
        ? { address: ip_v6_address }
        : { address: network_location })
    })
  }, [addressType]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledBlock theme={theme}>
      <Block
        title={`${role} Node`}
        titleIcon={{
          name: 'ComputerScreen2',
          ...(role === HIGH_AVAILABILITY_NODE_ROLE.PRIMARY
            ? { color: theme.colors.brand }
            : {}),
          ...(down_time ? { color: theme.colors.red500 } : {}),
          size: 24
        }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <div
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <RadioGroup
            inline={true}
            id={'addressType'}
            name='addressType'
            onChange={e => setAddressType(e.target.value)}
            selected={addressType}
          >
            <Radio
              id={`FQDN${index}`}
              label={`FQDN (${fqdn || 'not available'})`}
              disabled={!fqdn}
              value={'FQDN'}
            />
            <Radio
              id={`${IP_ADDRESS_TYPE.IPV6}${index}`}
              label={`IPv6 (${ip_v6_address || 'not assigned'})`}
              disabled={!ip_v6_address}
              value={IP_ADDRESS_TYPE.IPV6}
            />
            <Radio
              id={`${IP_ADDRESS_TYPE.IPV4}${index}`}
              label={`IPv4 (${network_location || 'not assigned'})`}
              disabled={!network_location}
              value={IP_ADDRESS_TYPE.IPV4}
            />
          </RadioGroup>
        </div>
      </Block>
    </StyledBlock>
  )
}
