import {
  useAppNotifications,
  useFetch,
  ModalButtons,
  FormikError,
  ModalSpinner
} from 'common'
import { KaizenThemeContext } from '@kaizen-ui/styles'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import React, { useGlobal } from 'reactn'
import { API_EMAIL_ALERTS, EMAIL_ALERT_TYPES } from '../../../api'
import { SessionExpired } from '../../../Components'
import { GLOBAL_ORG } from '../../../globalState'
import styled from 'styled-components'
import Switch from '@kaizen-ui/switch'
import Button from '@kaizen-ui/button'
import Textbox from '@kaizen-ui/textbox'
import Text from '@kaizen-ui/text'
import ReactTooltip from 'react-tooltip'

const StyledSwitch = styled(Switch)`
  margin: 0 0 1rem 0;
`

const validationSchema = Yup.object({
  type: Yup.string().oneOf(
    [
      EMAIL_ALERT_TYPES.API_KEY_EXPIRATION,
      EMAIL_ALERT_TYPES.FEATURE_EXPIRATION,
      EMAIL_ALERT_TYPES.LEASE_CONSUMPTION
    ],
    'Invalid alert type'
  ),
  emailIds: Yup.array().min(1, 'You must enter at least 1 email address'),
  threshold: Yup.number().required('You must provide a value for this field'),
  interval: Yup.number().required('You must provide a value for this field')
})

export const ConfigureAlert = ({ selectedAlert, onUpdate }) => {
  const { notify } = useAppNotifications()
  const [org] = useGlobal(GLOBAL_ORG)
  const orgId = org && org.id
  const [allUsers] = React.useState([])
  const { getData: sendRequest, loading, error } = useFetch({
    endpoint: API_EMAIL_ALERTS(orgId),
    actionLabel: `Configure e-mail alert type ${selectedAlert.type}`,
    SessionExpired: SessionExpired,
    method: 'PUT'
  })

  const getThresholdDefault = () => {
    if (selectedAlert.type === EMAIL_ALERT_TYPES.LEASE_CONSUMPTION) {
      return 60
    } else if (selectedAlert.type === EMAIL_ALERT_TYPES.FEATURE_EXPIRATION) {
      return 15
    } else {
      return 5
    }
  }

  const initialValues = {
    type: selectedAlert.type,
    emailIds: selectedAlert.alert?.emailIds || [],
    disable: selectedAlert.alert?.disable || false,
    threshold: selectedAlert.alert?.threshold || getThresholdDefault(),
    interval: parseInt(selectedAlert.alert?.interval) || 1
  }

  const onSubmit = async values => {
    const { type, emailIds, disable, threshold, interval } = formik.values

    const leaseBody =
      type === EMAIL_ALERT_TYPES.LEASE_CONSUMPTION
        ? { interval: parseInt(interval) }
        : {}
    const body = {
      alertConfigs: [
        {
          alertType: type,
          emailIds,
          disable,
          threshold: parseInt(threshold),
          ...leaseBody
        }
      ]
    }
    const result = await sendRequest({ body })

    if (result) {
      notify(
        `Successfully created e-mail alert '${type}'`,
        null,
        null,
        'Created e-mail alert'
      )
      onUpdate && onUpdate()
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  })

  const selectBoxItems = React.useMemo(
    () =>
      formik.values.emailIds.map(email => {
        return {
          label: email,
          value: email,
          highlight: undefined
          // highlight: allUsers.find(user => user.email === email)
          //   ? undefined
          //   : "This e-mail address doesn't relate to a known user account in your organization"
        }
      }),
    [formik.values.emailIds] //, allUsers
  )

  return (
    <div>
      {loading && <ModalSpinner />}
      <div>
        {selectedAlert.alert && (
          <StyledSwitch
            checked={!formik.values.disable}
            label='Enabled'
            onChange={() =>
              formik.setFieldValue('disable', !formik.values.disable)
            }
          />
        )}
      </div>
      <SelectBox
        selectedItems={selectBoxItems}
        onChange={items => {
          formik.setFieldTouched('emailIds', true)
          formik.setFieldValue(
            'emailIds',
            items.map(item => item.value)
          )
        }}
        options={allUsers}
        label='E-mail addresses to notify'
        placeholder='Enter an e-mail address'
      />
      {formik.touched.emailIds && formik.errors.emailIds && (
        <FormikError>{formik.errors.emailIds}</FormikError>
      )}
      <Textbox
        id='threshold'
        name='threshold'
        label={
          selectedAlert.type === EMAIL_ALERT_TYPES.API_KEY_EXPIRATION ||
          selectedAlert.type === EMAIL_ALERT_TYPES.FEATURE_EXPIRATION
            ? 'Number of days prior to expiration to receive notification'
            : 'Notify when feature lease consumption exceeds percentage'
        }
        className='nvl'
        placeholder='Enter a threshold'
        value={formik.values.threshold}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        validationMessage={formik.touched.threshold && formik.errors.threshold}
      />
      {selectedAlert.type === EMAIL_ALERT_TYPES.LEASE_CONSUMPTION && (
        <Textbox
          id='interval'
          name='interval'
          label='Ignore additional alerts occuring within the next hours'
          className='nvl'
          placeholder='Enter an interval'
          value={formik.values.interval}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          validationMessage={formik.touched.interval && formik.errors.interval}
        />
      )}
      <FormikError>{error}</FormikError>
      <ModalButtons>
        <Button
          onClick={formik.handleSubmit}
          disabled={loading || (selectedAlert.alert && !formik.dirty)}
        >
          {selectedAlert.alert ? 'edit' : 'create'} alert
        </Button>
      </ModalButtons>
    </div>
  )
}

const Outer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.textbox.normal.border};
  background-color: ${({ theme }) => theme.colors.textbox.normal.background};
  height: 10rem;
  overflow: auto;
`
const Row = styled.div`
  :hover {
    opacity: 0.5;
  }

  display: flex;
  align-items: center;
  padding: 0.5rem;
  ${({ highlight }) => (highlight ? `font-style: italic;` : '')}
`
const Label = styled.div`
  flex: 1 0 auto;
`
const StyledButton = styled(Button)`
  padding: 0 !important;
`
const Header = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 0.5rem;
  }
`

const SelectBox = ({
  options,
  selectedItems = [],
  label,
  onChange,
  placeholder = 'Enter a value to add to the list'
}) => {
  const theme = React.useContext(KaizenThemeContext)
  const [txt, setTxt] = React.useState('')
  const textValid = React.useMemo(() => {
    const reg = /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/
    return reg.test(txt)
  }, [txt])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [selectedItems])

  const addToList = () => {
    const filteredOptions = selectedItems.filter(item => item.value !== txt)

    onChange && onChange([...filteredOptions, { label: txt, value: txt }])

    setTxt('')
  }

  const removeFromList = value => {
    const filteredOptions = selectedItems.filter(item => item.value !== value)

    onChange && onChange(filteredOptions)

    ReactTooltip.hide()
  }

  return (
    <>
      <Header>
        <Textbox
          value={txt}
          onChange={({ target: { value } }) => setTxt(value)}
          placeholder={placeholder}
          pattern='[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'
          onKeyPress={({ code }) =>
            code === 'Enter' && textValid && addToList()
          }
        />
        <Button
          icon={{ name: 'ActionsAdd' }}
          size='small'
          variant='outline'
          onClick={addToList}
          disabled={!textValid}
        ></Button>
      </Header>
      {label && <Text textStyle='label'>{label}</Text>}
      <Outer theme={theme}>
        {selectedItems.map(item => (
          <Row
            key={item.value}
            data-tip={item.highlight}
            highlight={item.highlight}
          >
            <Label>
              <Text
                overflow='truncate'
                color={
                  item.highlight ? theme.colors.textbox.placeholder : 'inherit'
                }
              >
                {/* {item.highlight && <Icon name='StatusWarning' />} */}
                {item.label}
              </Text>
            </Label>
            <div data-tip='Remove this item from the list'>
              <StyledButton
                icon={{ name: 'ActionsCircleDelete', variant: 'solid' }}
                variant='link'
                type='secondary'
                onClick={() => removeFromList(item.value)}
              />
            </div>
          </Row>
        ))}
      </Outer>
    </>
  )
}
