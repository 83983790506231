import React, { useState, useEffect } from 'react'
import { useAppNotifications, useFetch } from 'common'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components'
import Textbox from '@kaizen-ui/textbox'
import Text from '@kaizen-ui/text'
import Switch from '@kaizen-ui/switch'
import Button from '@kaizen-ui/button'
import Result from '@kaizen-ui/result'
import Radio, { RadioGroup } from '@kaizen-ui/radio'
import {
  API_SERVICE_INSTANCE_CONFIGURE_HA,
  API_SERVICE_INSTANCE_PING,
  DomainNameRegex,
  HIGH_AVAILABILITY_STATE,
  IP_ADDRESS_TYPE,
  IpHostnameRegex
} from '../../api'

const Row = styled.div`
  display: flex;
  align-items: center;
`
const StyledSwitch = styled.div`
  > div {
    margin: 0 0 1rem 0;

    .switch-display,
    label {
      margin-right: 0 !important;
    }

    .switch-text {
      margin-left: 0.5rem;
    }
  }
`

const Radios = styled.div`
  > div {
    justify-content: center;
  }
`

export const ConfigureHighAvailability = ({
  ip,
  ipv6,
  fqdn,
  onUpdate,
  haState
}) => {
  const { notify } = useAppNotifications()
  const [pingResult, setPingResult] = useState(undefined)
  const [submitting, setSubmitting] = useState(false)

  const initialValues = {
    networkLocation: '',
    useHa: haState === HIGH_AVAILABILITY_STATE.CLUSTERED,
    addressType: IP_ADDRESS_TYPE.IPV4
  }
  const { getData: sendPing, loading: pinging } = useFetch({
    endpoint: API_SERVICE_INSTANCE_PING,
    actionLabel: 'Pinging node for HA setup',
    method: 'POST'
  })
  const { getData: sendHaConfig, error, resetError } = useFetch({
    endpoint: API_SERVICE_INSTANCE_CONFIGURE_HA,
    actionLabel: 'Configuring high availability',
    method: 'POST'
  })

  const validationSchema = Yup.object({
    networkLocation: Yup.string()
      .notOneOf(
        [ip, fqdn, ipv6],
        "Value can't be same as current node's IP address or FQDN"
      )
      .matches(IpHostnameRegex, 'Must be a valid IP address or FQDN')
      .required('Network location of secondary node is required')
  })

  const onSubmit = async values => {
    const { networkLocation, useHa, addressType } = values
    setPingResult(undefined)
    const body = {
      networkLocation: networkLocation?.trim(),
      useHa,
      ...(useHa && DomainNameRegex.test(networkLocation) ? { addressType } : {})
    }
    const result = await sendPing({ body })
    if (result) {
      setPingResult(result)
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit
  })

  const submitConfig = async () => {
    setSubmitting(true)
    const { useHa, networkLocation, addressType } = formik.values
    const body = {
      mode: useHa
        ? HIGH_AVAILABILITY_STATE.CLUSTERED
        : HIGH_AVAILABILITY_STATE.STANDALONE,
      config: {
        nodeList: [
          {
            network_location: networkLocation
          }
        ]
      },
      ...(useHa
        ? {
            op: 'dynamic',
            ...(DomainNameRegex.test(networkLocation) ? { addressType } : {})
          }
        : {})
    }

    const result = await sendHaConfig({ body })
    if (result) {
      const msg = useHa
        ? 'High availability configuration triggered successfully'
        : 'Standalone node configuration triggered successfully'
      notify(msg, null, null, 'HA mode changed')
      onUpdate && onUpdate()
    } else {
      setSubmitting(false)
    }
  }
  useEffect(() => {
    if (pingResult) {
      setPingResult(undefined)
    }
    if (error) {
      resetError()
    }
  }, [formik?.values?.networkLocation, formik?.values?.addressType]) //eslint-disable-line react-hooks/exhaustive-deps

  const isDomainEntered = formik?.values?.networkLocation
    ? DomainNameRegex.test(formik?.values?.networkLocation)
    : false

  return (
    <>
      <StyledSwitch>
        <Switch
          id={'ha-switch'}
          showText
          label='Enable high availability'
          checked={formik.values.useHa}
          onChange={() => formik.setFieldValue('useHa', !formik.values.useHa)}
        />
      </StyledSwitch>

      {formik.values.useHa ? (
        <>
          <div style={{ marginBottom: '-1rem' }}>
            <Text textStyle='optionLabel'>Network location</Text>
          </div>
          <Row>
            <Textbox
              id='networkLocation'
              name='networkLocation'
              className='nvl'
              placeholder='IP address or FQDN of secondary node'
              value={formik.values.networkLocation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              validationMessage={
                formik.touched.networkLocation && formik.errors.networkLocation
              }
            />
          </Row>

          {isDomainEntered ? (
            <>
              <Row>
                <div>
                  <Text textStyle='optionLabel'>
                    FQDN resolution address type: &#160;
                  </Text>
                </div>
                <Radios>
                  <RadioGroup
                    inline={true}
                    id={'addressType'}
                    name='addressType'
                    onChange={e =>
                      formik.setFieldValue('addressType', e.target.id)
                    }
                    selected={formik?.values?.addressType}
                  >
                    <Radio id={IP_ADDRESS_TYPE.IPV4} label='IPv4' />
                    <Radio id={IP_ADDRESS_TYPE.IPV6} label='IPv6' />
                  </RadioGroup>
                </Radios>
              </Row>
              <br />
            </>
          ) : null}

          <Row>
            <Button
              variant='outline'
              type={'primary'}
              icon={{ name: 'ObjectsNetwork' }}
              onClick={formik.handleSubmit}
              disabled={pinging || !formik.values.networkLocation}
            >
              Ping
            </Button>
          </Row>
          <br />
          <Result
            status={
              submitting
                ? 'loading'
                : !!error
                ? 'error'
                : pinging
                ? 'loading'
                : !pingResult
                ? 'info'
                : pingResult?.reachable
                ? 'success'
                : 'error'
            }
            title={
              submitting
                ? 'Configuring HA'
                : !!error
                ? undefined
                : pinging
                ? 'Pinging node'
                : !pingResult
                ? ''
                : pingResult?.reachable
                ? 'Success!'
                : undefined
            }
            subTitle={
              submitting
                ? 'Configuring HA'
                : !!error
                ? error
                : pinging
                ? 'Checking node status'
                : !pingResult
                ? 'Awaiting node information'
                : pingResult?.reachable
                ? "Click 'Create Cluster' to enable high availability"
                : pingResult?.failure_detail || 'Unable to reach the node'
            }
            actions={
              pingResult?.reachable
                ? [
                    {
                      children: 'Create Cluster',
                      onClick: submitConfig,
                      disabled: submitting,
                      key: 0
                    }
                  ]
                : undefined
            }
          />
        </>
      ) : (
        <>
          {haState === HIGH_AVAILABILITY_STATE.STANDALONE ? (
            <Result
              status={'info'}
              title={'STANDALONE'}
              subTitle={'Standalone mode is already enabled on this instance'}
            />
          ) : (
            <Result
              status={submitting ? 'loading' : !!error ? 'error' : 'info'}
              title={
                submitting
                  ? 'Configuring HA'
                  : !!error
                  ? undefined
                  : haState === HIGH_AVAILABILITY_STATE.CLUSTERED
                  ? 'Decluster?'
                  : ''
              }
              subTitle={
                submitting
                  ? 'Configuring HA'
                  : !!error
                  ? error
                  : haState === HIGH_AVAILABILITY_STATE.CLUSTERED
                  ? 'This will remove the other node from the cluster'
                  : ''
              }
              actions={[
                {
                  children: 'Create Standalone',
                  onClick: submitConfig,
                  disabled: submitting,
                  key: 0
                }
              ]}
            />
          )}
        </>
      )}
    </>
  )
}
